import gql from 'graphql-tag'

const TipoComprobanteModel = {
    getAll:(client, recursos) => client.query({
        query: gql`{
          comprobantes {
            ${recursos}
          }
        }`,
        fetchPolicy: 'no-cache'
      })
}
export default TipoComprobanteModel