import React, {useEffect, useState} from "react"
import {Col, Row, Table} from "reactstrap"
import moment from "moment"

import {useAuth} from "../../../Context"
import {Empresa} from "../../../Components/Models"
import {useSelect} from "../../../hooks"
import MyLabel from "../../../Components/MyLabel"

const CronogramaObligaciones = () => {
    const {client, company} = useAuth()

    const [le, setLe] = useState([
        {
            periodo: 'Ene-2022', d0: '14 Feb', d1: '15 Feb', d23: '16 Feb', d45: '17 Feb', d67: '18 Feb', d89: '21 Feb',
            bc: '22 Feb', key: '2022-01'
        },
        {
            periodo: 'Feb-2022', d0: '14 Mar', d1: '15 Mar', d23: '16 Mar', d45: '17 Mar', d67: '18 Mar', d89: '21 Mar',
            bc: '22 Mar', key: '2022-02'
        },
        {
            periodo: 'Mar-2022', d0: '18 Abr', d1: '19 Abr', d23: '20 Abr', d45: '21 Abr', d67: '22 Abr', d89: '25 Abr',
            bc: '26 Abr', key: '2022-03'
        },
        {
            periodo: 'Abr-2022', d0: '16 May', d1: '17 May', d23: '18 May', d45: '19 May', d67: '20 May', d89: '23 May',
            bc: '24 May', key: '2022-04'
        },
        {
            periodo: 'May-2022', d0: '14 Jun', d1: '15 Jun', d23: '16 Jun', d45: '17 Jun', d67: '20 Jun', d89: '21 Jun',
            bc: '22 Jun', key: '2022-05'
        },
        {
            periodo: 'Jun-2022', d0: '14 Jul', d1: '15 Jul', d23: '18 Jul', d45: '19 Jul', d67: '20 Jul', d89: '21 Jul',
            bc: '22 Jul', key: '2022-06'
        },
        {
            periodo: 'Jul-2022', d0: '11 Ago', d1: '12 Ago', d23: '15 Ago', d45: '16 Ago', d67: '17 Ago', d89: '18 Ago',
            bc: '19 Ago', key: '2022-07'
        },
        {
            periodo: 'Ago-2022', d0: '13 Set', d1: '14 Set', d23: '15 Set', d45: '16 Set', d67: '19 Set', d89: '20 Set',
            bc: '21 Set', key: '2022-08'
        },
        {
            periodo: 'Set-2022', d0: '13 Oct', d1: '14 Oct', d23: '17 Oct', d45: '18 Oct', d67: '19 Oct', d89: '20 Oct',
            bc: '21 Oct', key: '2022-09'
        },
        {
            periodo: 'Oct-2022', d0: '14 Nov', d1: '15 Nov', d23: '16 Nov', d45: '17 Nov', d67: '18 Nov', d89: '21 Nov',
            bc: '22 Nov', key: '2022-10'
        },
        {
            periodo: 'Nov-2022', d0: '14 Dic', d1: '15 Dic', d23: '16 Dic', d45: '19 Dic', d67: '20 Dic', d89: '21 Dic',
            bc: '22 Dic', key: '2022-11'
        },
        {
            periodo: 'Dic-2022', d0: '12 Ene 2023', d1: '13 Ene 2023', d23: '16 Ene 2023', d45: '17 Ene 2023',
            d67: '18 Ene 2023', d89: '19 Ene 2023', bc: '20 Ene 2023 (*)', key: '2022-12'
        }
    ])
    const [c621, setC621] = useState([
        {
            periodo: 'Ene-2022', d0: '15 Feb', d1: '16 Feb', d23: '17 Feb', d45: '18 Feb', d67: '21 Feb', d89: '22 Feb',
            bc: '23 Feb', key: '2022-01'
        },
        {
            periodo: 'Feb-2022', d0: '15 Mar', d1: '16 Mar', d23: '17 Mar', d45: '18 Mar', d67: '21 Mar', d89: '22 Mar',
            bc: '23 Mar', key: '2022-02'
        },
        {
            periodo: 'Mar-2022', d0: '19 Abr', d1: '20 Abr', d23: '21 Abr', d45: '22 Abr', d67: '25 Abr', d89: '26 Abr',
            bc: '27 Abr', key: '2022-03'
        },
        {
            periodo: 'Abr-2022', d0: '17 May', d1: '18 May', d23: '19 May', d45: '20 May', d67: '23 May', d89: '24 May',
            bc: '25 May', key: '2022-04'
        },
        {
            periodo: 'May-2022', d0: '15 Jun', d1: '16 Jun', d23: '17 Jun', d45: '20 Jun', d67: '21 Jun', d89: '22 Jun',
            bc: '23 Jun', key: '2022-05'
        },
        {
            periodo: 'Jun-2022', d0: '15 Jul', d1: '18 Jul', d23: '19 Jul', d45: '20 Jul', d67: '21 Jul', d89: '22 Jul',
            bc: '25 Jul', key: '2022-06'
        },
        {
            periodo: 'Jul-2022', d0: '12 Ago', d1: '15 Ago', d23: '16 Ago', d45: '17 Ago', d67: '18 Ago', d89: '19 Ago',
            bc: '22 Ago', key: '2022-07'
        },
        {
            periodo: 'Ago-2022', d0: '14 Set', d1: '15 Set', d23: '16 Set', d45: '19 Set', d67: '20 Set', d89: '21 Set',
            bc: '22 Set', key: '2022-08'
        },
        {
            periodo: 'Set-2022', d0: '14 Oct', d1: '17 Oct', d23: '18 Oct', d45: '19 Oct', d67: '20 Oct', d89: '21 Oct',
            bc: '24 Oct', key: '2022-09'
        },
        {
            periodo: 'Oct-2022', d0: '15 Nov', d1: '16 Nov', d23: '17 Nov', d45: '18 Nov', d67: '21 Nov', d89: '22 Nov',
            bc: '23 Nov', key: '2022-10'
        },
        {
            periodo: 'Nov-2022', d0: '15 Dic', d1: '16 Dic', d23: '19 Dic', d45: '20 Dic', d67: '21 Dic', d89: '22 Dic',
            bc: '23 Dic', key: '2022-11'
        },
        {
            periodo: 'Dic-2022', d0: '13 Ene 2023', d1: '16 Ene 2023', d23: '17 Ene 2023', d45: '18 Ene 2023',
            d67: '19 Ene 2023', d89: '20 Ene 2023', bc: '23 Ene 2023', key: '2022-12'
        }
    ])
    const [senati, setSenati] = useState([
        {mes: 'Enero', fecha: '16 Feb', key: '2022-01'},
        {mes: 'Febrero', fecha: '16 Mar', key: '2022-02'},
        {mes: 'Marzo', fecha: '20 Abr', key: '2022-03'},
        {mes: 'Abril', fecha: '17 May', key: '2022-04'},
        {mes: 'Mayo', fecha: '16 Jun', key: '2022-05'},
        {mes: 'Junio', fecha: '18 Jul', key: '2022-06'},
        {mes: 'Julio', fecha: '16 Ago', key: '2022-07'},
        {mes: 'Agosto', fecha: '16 Set', key: '2022-08'},
        {mes: 'Setiembre', fecha: '18 Oct', key: '2022-09'},
        {mes: 'Octubre', fecha: '17 Nov', key: '2022-10'},
        {mes: 'Noviembre', fecha: '19 Dic', key: '2022-11'},
        {mes: 'Diciembre', fecha: '17 Ene 2023', key: '2022-12'},
    ])

    const circle = {background: 'rgba(217, 37, 80, 1)', borderRadius: '9px', color: '#FFF'}
    const styleTd = {fontSize: '0.7rem', textAlign: 'center', padding: '0 2px 0 2px'}
    const styleTh = {fontSize: '0.7rem', textAlign: 'center', padding: '0', fontWeight: '700'}

    const [lastDigitRuc, setLastDigitRuc] = useState('')
    const [empresas, setEmpresas] = useState([])

    const [empresa, selectEmpresa, setEmpresa, , optionsEmpresa] = useSelect({
        initialMessage: 'Seleccione una Empresa'
    })

    useEffect(() => {
        if (!company.id) return
        Empresa
            .getAll(client, 'id, ruc, razon_social')
            .then(response => {
                const {empresas} = response.data
                optionsEmpresa(empresas.map(({id, ruc, razon_social}) => ({
                    value: id, label: `${ruc} - ${razon_social}`
                })))
                setEmpresas(empresas)
                // if (!company) return
                const eFind = empresas.find(({id}) => id === company.id)
                if (eFind) setEmpresa(eFind.id)
            })

        const periodo_actual = moment().format('YYYY-MM')
        let findIndex = le.findIndex(({key}) => key === periodo_actual)
        findIndex = findIndex > 0 ? findIndex - 1 : findIndex
        const month_last = findIndex < 9 ? findIndex + 3 : findIndex
        setLe(prevState => prevState.slice(findIndex, month_last))
        setC621(prevState => prevState.slice(findIndex, month_last))
        setSenati(prevState => prevState.slice(findIndex, month_last))
    }, [])

    useEffect(() => {
        if (empresa === '') return
        const {ruc} = empresas.find(({id}) => id === empresa)
        setLastDigitRuc(ruc.substring(ruc.length - 1, ruc.length))
    }, [empresa])

    return (
        <Row>
            <Col className="offset-3 mb-1" md={6}>
                <MyLabel name='Empresas' requerid/>
                {selectEmpresa}
            </Col>
            <Col md={6}>
                <Table bordered className="mb-0">
                    <thead style={{textAlign: 'center', background: 'rgba(111, 213, 252, 0.3)', color: '#096da9'}}>
                    <tr>
                        <th colSpan={8} style={styleTh}>LIBROS ELECTRÓNICOS</th>
                    </tr>
                    <tr>
                        <th rowSpan={2} style={styleTh}>PERÍODO TRIBUTARIO</th>
                        <th colSpan={7} style={styleTh}>FECHA DE VENCIMIENTO SEGÚN EL ÚLTIMO DÍGITO DEL RUC</th>
                    </tr>
                    <tr>
                        <th style={{...styleTh, minWidth: '3rem'}}>0</th>
                        <th style={{...styleTh, minWidth: '3rem'}}>1</th>
                        <th style={{...styleTh, minWidth: '3rem'}}>2 y 3</th>
                        <th style={{...styleTh, minWidth: '3rem'}}>4 y 5</th>
                        <th style={{...styleTh, minWidth: '3rem'}}>6 y 7</th>
                        <th style={{...styleTh, minWidth: '3rem'}}>8 y 9</th>
                        <th style={styleTh}>BUENOS CONTRIBUYENTES Y UESP</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        le.map(({periodo, d0, d1, d23, d45, d67, d89, bc, key}, index) => {
                            const periodo_actual = moment().format('YYYY-MM')
                            const periodo_anterior = moment().subtract(1, 'months').format('YYYY-MM')
                            return (
                                <tr key={index}>
                                    <td style={periodo_actual === key ? {...styleTd, ...circle} : styleTd}>{periodo}</td>
                                    <td style={['0'].includes(lastDigitRuc) && periodo_anterior === key ? {...styleTd, ...circle} : styleTd}>{d0}</td>
                                    <td style={['1'].includes(lastDigitRuc) && periodo_anterior === key ? {...styleTd, ...circle} : styleTd}>{d1}</td>
                                    <td style={['2', '3'].includes(lastDigitRuc) && periodo_anterior === key ? {...styleTd, ...circle} : styleTd}>{d23}</td>
                                    <td style={['4', '5'].includes(lastDigitRuc) && periodo_anterior === key ? {...styleTd, ...circle} : styleTd}>{d45}</td>
                                    <td style={['6', '7'].includes(lastDigitRuc) && periodo_anterior === key ? {...styleTd, ...circle} : styleTd}>{d67}</td>
                                    <td style={['8', '9'].includes(lastDigitRuc) && periodo_anterior === key ? {...styleTd, ...circle} : styleTd}>{d89}</td>
                                    <td style={styleTd}>{bc}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
                <small>(*) BUENOS CONTRIBUYENTES Y UESP (Último dígito del RUC 2)</small>
            </Col>
            <Col md={6}>
                <Table bordered>
                    <thead style={{textAlign: 'center', background: 'rgba(111, 213, 252, 0.3)', color: '#096da9'}}>
                    <tr>
                        <th colSpan={8} style={styleTh}>OBLIGACIONES MENSUALES</th>
                    </tr>
                    <tr>
                        <th rowSpan={2} style={styleTh}>PERÍODO TRIBUTARIO</th>
                        <th colSpan={7} style={styleTh}>FECHA DE VENCIMIENTO SEGÚN EL ÚLTIMO DÍGITO DEL RUC</th>
                    </tr>
                    <tr>
                        <th style={{...styleTh, minWidth: '3rem'}}>0</th>
                        <th style={{...styleTh, minWidth: '3rem'}}>1</th>
                        <th style={{...styleTh, minWidth: '3rem'}}>2 y 3</th>
                        <th style={{...styleTh, minWidth: '3rem'}}>4 y 5</th>
                        <th style={{...styleTh, minWidth: '3rem'}}>6 y 7</th>
                        <th style={{...styleTh, minWidth: '3rem'}}>8 y 9</th>
                        <th style={styleTh}>BUENOS CONTRIBUYENTES Y UESP</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        c621.map(({periodo, d0, d1, d23, d45, d67, d89, bc, key}, index) => {
                            const periodo_actual = moment().format('YYYY-MM')
                            const periodo_anterior = moment().subtract(1, 'months').format('YYYY-MM')
                            return (
                                <tr key={index}>
                                    <td style={periodo_actual === key ? {...styleTd, ...circle} : styleTd}>{periodo}</td>
                                    <td style={['0'].includes(lastDigitRuc) && periodo_anterior === key ? {...styleTd, ...circle} : styleTd}>{d0}</td>
                                    <td style={['1'].includes(lastDigitRuc) && periodo_anterior === key ? {...styleTd, ...circle} : styleTd}>{d1}</td>
                                    <td style={['2', '3'].includes(lastDigitRuc) && periodo_anterior === key ? {...styleTd, ...circle} : styleTd}>{d23}</td>
                                    <td style={['4', '5'].includes(lastDigitRuc) && periodo_anterior === key ? {...styleTd, ...circle} : styleTd}>{d45}</td>
                                    <td style={['6', '7'].includes(lastDigitRuc) && periodo_anterior === key ? {...styleTd, ...circle} : styleTd}>{d67}</td>
                                    <td style={['8', '9'].includes(lastDigitRuc) && periodo_anterior === key ? {...styleTd, ...circle} : styleTd}>{d89}</td>
                                    <td style={styleTd}>{bc}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </Col>
            <Col md={6}>
                <Table bordered>
                    <thead style={{textAlign: 'center', background: 'rgba(111, 213, 252, 0.3)', color: '#096da9'}}>
                    <tr>
                        <th colSpan={8} style={styleTh}>SENATI</th>
                    </tr>
                    <tr>
                        <th style={styleTh}>MES</th>
                        <th style={styleTh}>FECHA VENCIMIENTO</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        senati.map(({mes, fecha, key}, index) => {
                            const periodo_actual = moment().format('YYYY-MM')
                            const periodo_anterior = moment().subtract(1, 'months').format('YYYY-MM')
                            return (
                                <tr key={index}>
                                    <td style={periodo_actual === key ? {...styleTd, ...circle} : styleTd}>{mes}</td>
                                    <td style={periodo_anterior === key ? {...styleTd, ...circle} : styleTd}>{fecha}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

export default CronogramaObligaciones