import gql from 'graphql-tag'

const ServiceModel = {
    createPackFacture: (client, {code, description, nrovoucher, observation}) => client.mutate({
        mutation: gql`mutation createPackFacture(
            $code        : String
            $description : String
            $nrovoucher  : Int
            $observation : String
        ){
            createPackFacture(
                code        : $code
                description : $description
                nrovoucher  : $nrovoucher
                observation : $observation
            ){
                id
            }
        }`,
        variables: {
            code, description, nrovoucher: Number(nrovoucher), observation
        }
    }),

    updatesPackFacture: (client, {id, code, description, nrovoucher, observation}) => client.mutate({
        mutation: gql`mutation updatesPackFacture(
            $id          : String
            $code        : String
            $description : String
            $nrovoucher  : Int
            $observation : String
        ){
            updatesPackFacture(
                id          : $id
                code        : $code
                description : $description
                nrovoucher  : $nrovoucher
                observation : $observation
            ){
                id
            }
        }`,
        variables: {
            id, code, description, nrovoucher: Number(nrovoucher), observation
        }
    }),

    statusPackFacture: (client, {id, enable}) => client.mutate({
        mutation: gql`mutation statusPackFacture(
                $id:     String
                $enable: Boolean
            ){
                statusPackFacture(
                    id:     $id
                    enable: $enable
                )
                {
                    id
                }
            }
        `,
        variables: {
            id, enable
        }
    })
}

export default ServiceModel