import gql from 'graphql-tag'

const PedidoModel = {
    createOrUpdate: (client, pedido, recursos) => client.mutate({
        mutation: gql`mutation createOrUpdatePedido($id: String, $venta: JSON!) {
                createOrUpdatePedido(id: $id, venta: $venta) {${recursos}}
            }`,
        variables: {
            ...pedido,
            id: pedido.id ? pedido.id : ''
        }
    }),
    getAll: (client, {caja_id, zona_id, user_id}, recursos) => client.query({
        query: gql`query getPedidos($caja_id: String, $zona_id: String, $user_id: String) {
            pedidos(caja_id: $caja_id, zona_id: $zona_id, user_id: $user_id) {
                  result
              }
          }`,
        variables: {caja_id, zona_id, user_id},
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query getPedido($id: String!) {
                pedido(id: $id) {
                      ${recursos}
                  }
              }`,
        variables: {
            id
        },
        fetchPolicy: 'no-cache'
    }),
    getByZona: (client, zona_id, recursos) => client.query({
        query: gql`query getPedidoByZona($zona_id: String!) {
                pedidoByZona(zona_id: $zona_id) {
                      ${recursos}
                  }
              }`,
        variables: {zona_id},
        fetchPolicy: 'no-cache'
    }),
    getByMesaId: (client, mesa_id, recursos) => client.query({
        query: gql`query getPedidoByMesaId($mesa_id: String!) {
                pedidoByMesaId(mesa_id: $mesa_id) {
                      ${recursos}
                  }
              }`,
        variables: {mesa_id},
        fetchPolicy: 'no-cache'
    }),
    deleted: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deletedPedido($id: String!){
                  deletedPedido(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id
        }
    }),
    newPedido: (client, {zona_id, user_id, role_id}, recursos) => client.subscribe({
        query: gql`
        subscription newPedido($zona_id: String!, $user_id: String!, $role_id: String!) {
          newPedidoByZonaId(zona_id: $zona_id, user_id: $user_id, role_id: $role_id) {
            ${recursos}
          }
        }`,
        variables: {zona_id, user_id, role_id}
    }),
    getByFecha: (client, {caja_id, fecha_Inicio, fecha_Fin}, recursos) => client.query({
        query: gql`query pedidosByFecha($caja_id: String!, $fecha_Inicio: String!, $fecha_Fin: String!) {
            pedidosByFecha(caja_id: $caja_id, fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin) {
                  result
              }
          }`,
        variables: {caja_id, fecha_Inicio, fecha_Fin},
        fetchPolicy: 'no-cache'
    }),
    reporteByCajaId: (client, {caja_id, fecha_Inicio, fecha_Fin, cerrar, pdf}, recursos) => client.query({
        query: gql`query reporteByCajaId($caja_id: String!, $fecha_Inicio: String, $fecha_Fin: String, $cerrar: Boolean, $pdf: Boolean) {
            reporteByCajaId(caja_id: $caja_id, fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin, cerrar: $cerrar, pdf: $pdf) {
                  ${recursos}
              }
          }`,
        variables: {caja_id, fecha_Inicio, fecha_Fin, cerrar, pdf},
        fetchPolicy: 'no-cache'
    }),
    getPedidosCaja: (client, {caja_id, fecha_Inicio, fecha_Fin}, recursos) => client.query({
        query: gql`query getPedidosCaja($caja_id: String!, $fecha_Inicio: String, $fecha_Fin: String) {
            getPedidosCaja(caja_id: $caja_id, fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin) {
                  ${recursos}
              }
          }`,
        variables: {caja_id, fecha_Inicio, fecha_Fin},
        context: {timeout: 480000},
        fetchPolicy: 'no-cache'
    }),
    getByVendedor: (client, {fecha_Inicio, fecha_Fin}, recursos) => client.query({
        query: gql`query pedidosByVendedor($fecha_Inicio: String, $fecha_Fin: String) {
            pedidosByVendedor(fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin) {
                  ${recursos}
              }
          }`,
        variables: {fecha_Inicio, fecha_Fin},
        fetchPolicy: 'no-cache'
    }),
}

export default PedidoModel