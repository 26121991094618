import gql from "graphql-tag"

const Home = {
    comprasVentasByMonths: (client, recursos) => client.query({
        query: gql`{
              comprasVentasByMonths {
                ${recursos}
              }
            }`,
        fetchPolicy: "no-cache"
    }),
}

export default Home