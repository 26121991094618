import React from "react"
import {Card, CardBody} from "reactstrap"

import ComprasVentas from "./Components/ComprasVentas"
import RankingProductosProveedoresClientes from "./Components/RankingProductosProveedoresClientes"

const Facturacion = () => {

    return (
        <Card className='main-card'>
            <CardBody>
                <ComprasVentas/>
                <RankingProductosProveedoresClientes/>
            </CardBody>
        </Card>
    )
}

export default Facturacion