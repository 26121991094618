import React, {Suspense, lazy, useEffect} from 'react'
import {Route, Redirect} from 'react-router-dom'
import Loader from 'react-loaders'
import {ApolloClient} from 'apollo-client'
import gql from 'graphql-tag'
import {ApolloLink, Observable} from 'apollo-link'
import {onError} from 'apollo-link-error'
// import {createHttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {getMainDefinition} from 'apollo-utilities'
import {WebSocketLink} from 'apollo-link-ws'
import ApolloLinkTimeout from 'apollo-link-timeout'
import {useToasts} from 'react-toast-notifications'
import {createUploadLink} from 'apollo-upload-client'
import {HttpLink} from "apollo-link-http"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

import {AuthContext} from '../../Context'
import PrivateRoute from '../../PrivateRoute'
import Login from '../../Views/Auth/Login'
import Register from "../../Views/Auth/Register"
import Reset from '../../Views/Auth/Reset'
import Verify from '../../Views/Auth/Verify'
import Home from '../../Views/Home'
import Modulos from '../../Views/App/Modulos'
import Libro from '../../Views/LibroReclamacion'
import LibroSearch from '../../Views/LibroReclamacion/LibroSearch'

import useLocalStorage from '../../hooks/useLocalStorage'
import {Auth} from "../../Components/Models"
import {decodeToken, isToken} from "../../utils/scripts"

const App = lazy(() => import('../../Views/App'))
const Admin = lazy(() => import('../../Views/Admin'))
const Contable = lazy(() => import('../../Views/Contable'))
const Productos = lazy(() => import('../../Views/Productos'))
const Planillas = lazy(() => import('../../Views/Planillas'))
const Pos = lazy(() => import('../../Views/POS'))
const MccPos = lazy(() => import('../../Views/MCCPOS'))
const Facturador = lazy(() => import('../../Views/Facturador'))
const Reportes = lazy(() => import('../../Views/Reportes'))
const Utilitarios = lazy(() => import('../../Views/Utilitarios'))
const Aplicativos = lazy(() => import('../../Views/Aplicativos'))
const Pdts = lazy(() => import('../../Views/PDTs'))
const SlePle51 = lazy(() => import('../../Views/SlePle51'))
const ProcesosSol = lazy(() => import('../../Views/Operaciones'))
const Xml = lazy(() => import('../../Views/Registro'))
const RegistrosContables = lazy(() => import('../../Views/LrContables'))
const Multimedia = lazy(() => import("../../Views/Multimedia"))

require('dotenv').config()

const MySwal = withReactContent(Swal)
let client, appapi

const AppMain = () => {
    const {addToast, removeAllToasts} = useToasts()

    const [auth, setAuth] = useLocalStorage('Auth', {})
    const [auth2, setAuth2] = useLocalStorage('Auth2', {})
    const [company, setCompany] = useLocalStorage('Company', {})
    const [company2, setCompany2] = useLocalStorage('localempresa', {})
    let count = true

    const logout = () => {
        localStorage.setItem('Auth', JSON.stringify({}))
        localStorage.setItem('Auth2', JSON.stringify({}))
        localStorage.setItem('localempresa', JSON.stringify({}))
        localStorage.setItem('Company', JSON.stringify({}))
        window.location.href = '/login'
    }

    const setClient = au => {
        const {role_id} = au.authentication ? decodeToken(au.authentication) : {}
        if (['1990d988-220c-11ec-9b6d-335378e45614', '129965aa-220c-11ec-adf6-5784bddb7a64', '0f17e438-220c-11ec-adf5-77be0344e22b'].includes(role_id))
            au = {...au, modulo_id: '24414f8c-1a66-11ec-9149-dfe7ef2f825e'}
        setAuth(au)
        const timeoutLink = new ApolloLinkTimeout(120000) // Milliseconds
        let {authentication} = JSON.parse(localStorage.getItem('Auth'))
        client = new ApolloClient({
            link: ApolloLink.from([
                onError(({graphQLErrors, networkError, operation, forward}) => {
                    if (networkError) {
                        const {statusCode} = networkError
                        switch (statusCode) {
                            case 401:
                                const {refresh} = JSON.parse(localStorage.getItem('Auth'))
                                return new Observable(observer => {
                                    Auth
                                        .refresh(client, {refresh: `Bearer ${refresh}`}, 'authentication, refresh')
                                        .then(response => {
                                            let {refresh} = response.data

                                            if (response.error || refresh === null) {
                                                logout()
                                                return
                                            }

                                            operation.setContext({headers: {Authentication: `Bearer ${refresh.authentication}`}})

                                            forward(operation)
                                                .subscribe({
                                                    next: observer.next.bind(observer),
                                                    error: observer.error.bind(observer),
                                                    complete: observer.complete.bind(observer)
                                                })

                                            if (auth.modulo_id) refresh.modulo_id = auth.modulo_id
                                            setClient({
                                                ...JSON.parse(localStorage.getItem('Auth')),
                                                ...refresh
                                            })
                                        })
                                        .catch(error => {
                                            // No refresh or client token available, we force user to login
                                            observer.error(error)
                                            logout()
                                        })
                                })
                            case 402:
                                if (count === true) {
                                    const Toast = MySwal.mixin({
                                        toast: true,
                                        position: 'center',
                                        showConfirmButton: false,
                                        timer: 10000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', Swal.stopTimer)
                                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                                        }
                                    })

                                    Toast.fire({
                                        icon: 'error',
                                        title: 'Se ha Inicio Sessión en otro Dispositivo'
                                    })
                                    count = false
                                    setTimeout(() => logout(), 1000)
                                }
                                break
                            case 403:
                                addToast('No tiene los privilegios...', {appearance: 'error', autoDismissTimeout: 5000})
                                break
                            default:
                                break
                        }
                    }
                }),
                ApolloLink.split(({query}) => {
                        const {kind, operation} = getMainDefinition(query)
                        return kind === 'OperationDefinition' && operation === 'subscription'
                    },
                    new WebSocketLink({
                        uri: `${process.env.REACT_APP_SOCKET}://${process.env.REACT_APP_WS}/graphql`,
                        options: {
                            reconnect: true,
                            connectionParams: {
                                headers: {
                                    Authentication: `Bearer ${authentication}`
                                }
                            }
                        }
                    }),
                    timeoutLink.concat(createUploadLink({
                        uri: `${process.env.REACT_APP_API_ECOCONT}/api`,
                        headers: {
                            Authentication: `Bearer ${authentication}`
                        }
                    }))
                )
            ]),
            cache: new InMemoryCache()
        })
    }

    const setClient2 = au => {
        setAuth2(au)
        let {authentication} = JSON.parse(localStorage.getItem('Auth2'))

        appapi = new ApolloClient({
            link: ApolloLink.from([
                onError(({graphQLErrors, networkError, operation, forward}) => {
                    if (!networkError) return

                    switch (networkError.statusCode) {
                        case 401:
                            // User access token has expired
                            // We assume we have both tokens needed to run the async request
                            // Let's refresh token through async request
                            return new Observable(observer => {
                                appapi.query({
                                    query: gql`query refreshToken($authentication: String!) {
                                          refresh(authentication: $authentication) {
                                            authentication
                                            layout
                                          }
                                        }`,
                                    variables: {
                                        authentication: authentication
                                    },
                                    context: {headers: {isAuth: isToken()}},
                                    fetchPolicy: "no-cache"
                                })
                                    .then(response => {
                                        const {refresh} = response.data
                                        if (response.error || refresh === null) {
                                            return
                                        }

                                        operation.setContext({
                                            headers: {
                                                authentication: refresh.authentication,
                                                isAuth: isToken()
                                            }
                                        })

                                        forward(operation)
                                            .subscribe({
                                                next: observer.next.bind(observer),
                                                error: observer.error.bind(observer),
                                                complete: observer.complete.bind(observer)
                                            })

                                        setClient2(refresh)
                                    })
                                    .catch(error => {
                                        // No refresh or client token available, we force user to login
                                        observer.error(error)
                                    })
                            })
                        case 402:
                            if (count === true) {
                                // toast.error('Nueva sessión con sus credenciales en otro navegador...', {autoClose: 10000})
                                count = false
                                // setAuth2({})
                                // window.location.href = '/login'
                            }
                            break
                        case 403:
                            const {pathname, origin} = window.location
                            removeAllToasts()
                            if (pathname === '/aplicativos/dniplatinum') {
                                let phone = '+51 963 656 425'
                                switch (origin) {
                                    case 'https://www.grupseld.com':
                                    case 'https://grupseld.com':
                                        phone = '+51 959 525 541 / +51 920 553 640'
                                        break
                                }
                                toast.error(`Opción restringida, solicita información al ${phone}`, {autoClose: 10000})
                            } else
                                toast.error('Received status code 403', {autoClose: 10000})
                            break
                    }
                }),
                new HttpLink({
                    uri: `${process.env.REACT_APP_API_APPAPI}/api`,
                    headers: {
                        authentication,
                        isAuth: isToken()
                    }
                })
            ]),
            cache: new InMemoryCache(),
            name: 'Cliente de GRAPHQL para Aplicativos',
            version: '1.3',
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache'
                }
            }
        })
    }

    const toast = {
        success: (message, {autoClose} = {}) => {
            if (autoClose) addToast(message, {appearance: 'success', autoDismissTimeout: autoClose})
            else addToast(message, {appearance: 'success', autoDismiss: false})
        },
        error: (message, {autoClose}) => addToast(message, {appearance: 'error', autoDismissTimeout: autoClose}),
        warning: (message, {autoClose}) => addToast(message, {appearance: 'warning', autoDismissTimeout: autoClose}),
        info: (message, {autoClose}) => addToast(message, {appearance: 'info', autoDismissTimeout: autoClose})
    }

    useEffect(() => {
        setClient(auth)
        setClient2(auth2)
        setCompany(company)
        setCompany2(company2)
    }, [])

    return client && appapi ? (
        <AuthContext.Provider value={{
            auth, setAuth, auth2, setAuth2, client, appapi, setClient, setClient2, toast, removeAllToasts, company,
            setCompany, setCompany2, company2
        }}>
            <Route exact path='/login' component={Login}/>
            <Route exact path="/register" component={Register}/>
            <Route exact path='/password/reset' component={Reset}/>
            <Route exact path='/password/reset/:token' component={Reset}/>
            <Route exact path='/account/verify/:token' component={Verify}/>
            <Route exact path='/libro' component={Libro}/>
            <Route exact path='/libro-search' component={LibroSearch}/>

            <PrivateRoute path='/home' component={Home}/>
            <Route path='/modulos' component={Modulos}/>

            <Suspense fallback={<div className='loader-container'>
                <div className='loader-container-inner'>
                    <div className='text-center'>
                        <Loader type='ball-pulse-rise' active/>
                    </div>
                    <h6 className='mt-5'>Cargando los Componentes...</h6>
                </div>
            </div>}>
                <Route path='/app' component={App}/>
            </Suspense>

            <Suspense fallback={<div className='loader-container'>
                <div className='loader-container-inner'>
                    <div className='text-center'>
                        <Loader type='ball-pulse-rise' active/>
                    </div>
                    <h6 className='mt-5'>Cargando los Componentes...</h6>
                </div>
            </div>}>
                <Route path='/admin' component={Admin}/>
            </Suspense>

            <Suspense fallback={<div className='loader-container'>
                <div className='loader-container-inner'>
                    <div className='text-center'>
                        <Loader type='ball-pulse-rise' active/>
                    </div>
                    <h6 className='mt-5'>Cargando los Componentes...</h6>
                </div>
            </div>}>
                <Route path='/pla' component={Planillas}/>
            </Suspense>

            <Suspense fallback={<div className="loader-container">
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader type="ball-pulse-rise" active/>
                    </div>
                    <h6 className="mt-5">
                        Cargando los Componentes...
                    </h6>
                </div>
            </div>}>
                <Route path="/fac" component={Facturador}/>
            </Suspense>

            <Suspense fallback={<div className="loader-container">
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader type="ball-pulse-rise" active/>
                    </div>
                    <h6 className="mt-5">
                        Cargando los Componentes...
                    </h6>
                </div>
            </div>}>
                <Route path="/contable" component={Contable}/>
            </Suspense>

            <Suspense fallback={<div className="loader-container">
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader type="ball-pulse-rise" active/>
                    </div>
                    <h6 className="mt-5">
                        Cargando los Componentes...
                    </h6>
                </div>
            </div>}>
                <Route path="/prod" component={Productos}/>
            </Suspense>

            <Suspense fallback={<div className="loader-container">
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader type="ball-pulse-rise" active/>
                    </div>
                    <h6 className="mt-5">
                        Cargando los Componentes...
                    </h6>
                </div>
            </div>}>
                <Route path="/pos" component={Pos}/>
            </Suspense>
            <Suspense fallback={<div className="loader-container">
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader type="ball-pulse-rise" active/>
                    </div>
                    <h6 className="mt-5">
                        Cargando los Componentes...
                    </h6>
                </div>
            </div>}>
                <Route path="/repo" component={Reportes}/>
            </Suspense>

            <Suspense fallback={<div className="loader-container">
                <div className="loader-container-inner">
                    <div className="text-center">
                        <Loader type="ball-pulse-rise" active/>
                    </div>
                    <h6 className="mt-5">
                        Cargando los Componentes...
                    </h6>
                </div>
            </div>}>
                <Route path="/mcc" component={MccPos}/>
            </Suspense>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" active/>
                        </div>
                        <h6 className="mt-5">
                            Cargando los Componentes...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/util" component={Utilitarios}/>
            </Suspense>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" active/>
                        </div>
                        <h6 className="mt-5">
                            Cargando los Componentes...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/aplicativos" component={Aplicativos}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" active/>
                        </div>
                        <h6 className="mt-5">
                            Cargando los Componentes...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/pdts" component={Pdts}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" active/>
                        </div>
                        <h6 className="mt-5">
                            Cargando los Componentes...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/operaciones" component={ProcesosSol}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" active/>
                        </div>
                        <h6 className="mt-5">
                            Cargando los Componentes...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/sleple5.1" component={SlePle51}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" active/>
                        </div>
                        <h6 className="mt-5">
                            Cargando los Componentes...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/xml" component={Xml}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" active/>
                        </div>
                        <h6 className="mt-5">
                            Cargando los Componentes...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/lrcontables" component={RegistrosContables}/>
            </Suspense>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise" active/>
                        </div>
                        <h6 className="mt-5">
                            Cargando los Componentes...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/mm" component={Multimedia}/>
            </Suspense>

            {/*<Route path='/libro' render={() => <Redirect to={'/libro'}/>}/>*/}
            {/*<Route path='/libro-search' render={() => <Redirect to={'/libro-search'}/>}/>*/}
            <Route exact path='/' render={() => <Redirect to={'/login'}/>}/>
        </AuthContext.Provider>
    ) : (<></>)
}

export default AppMain
