import gql from "graphql-tag"

const Storage = {
    create: (client, {sc_050100, sc_080100, sc_140100}, recursos) => client.mutate({
        mutation: gql`mutation createStorage($sc_050100: JSON, $sc_080100: JSON, $sc_140100: JSON) {
            createStorage(sc_050100: $sc_050100, sc_080100: $sc_080100, sc_140100: $sc_140100) {
                ${recursos}
            }
        }`,
        variables: {sc_050100, sc_080100, sc_140100}
    }),
    getByUserId: (client, recursos) => client.query({
        query: gql`query storage {
                ${recursos}
            }`,
        fetchPolicy: "no-cache"
    }),
    update: (client, {sc_050100, sc_080100, sc_140100}, recursos) => client.mutate({
        mutation: gql`mutation updateStorage($update: JSON!){
            updateStorage(update: $update) {
                ${recursos}
            }
        }`,
        variables: {
            update: {sc_050100, sc_080100, sc_140100}
        }
    }),
    getTableSlePle51: (client, type, recursos) => client.query({
        query: gql`query createTable($type: String!) {
            tableSlePle51(type: $type) {
                ${recursos}
            }
        }`,
        context: {timeout: 960000},
        variables: {type},
        fetchPolicy: "no-cache"
    }),
    getContanciaPle51: (client, type, recursos) => client.query({
        query: gql`query getContanciaPle51($type: String!) {
            constanciaPle51(type: $type) {
                ${recursos}
            }
        }`,
        variables: {type},
        fetchPolicy: "no-cache"
    }),
    validateSlePle51: (client, {data, type}, recursos) => client.query({
        query: gql`query getValidateSlePle51($data: JSON, $type: String!) {
            validateSlePle51(data: $data, type: $type) {
                ${recursos}
            }
        }`,
        variables: {data, type},
        fetchPolicy: "no-cache"
    }),
    validarGd: (client, cuentas, recursos) => client.query({
        query: gql`query postValidarGd($cuentas: JSON!) {
                validarGd(cuentas: $cuentas) {
                ${recursos}
            }
        }`,
        variables: {cuentas},
        fetchPolicy: "no-cache"
    }),
    saveCompraDiario: (client, recursos) => client.query({
        query: gql`query {
              saveCompraDiario {
                ${recursos}
              }
            }`,
        context: {timeout: 960000},
        fetchPolicy: "no-cache"
    }),
    saveVentaDiario: (client, recursos) => client.query({
        query: gql`query {
              saveVentaDiario {
                ${recursos}
              }
            }`,
        context: {timeout: 960000},
        fetchPolicy: "no-cache"
    }),
    saveDiario: (client, recursos) => client.query({
        query: gql`query {
              saveDiario {
                ${recursos}
              }
            }`,
        context: {timeout: 960000},
        fetchPolicy: "no-cache"
    }),
}

export default Storage