import gql from 'graphql-tag'

const CategorysModel = {
    createCategory: (client, {code, name, id_module}) => client.mutate({
        mutation: gql`mutation createCategory(
            $code: String
            $name: String
            $id_module: String
        ){
            createCategory(
                code     : $code
                name     : $name
                id_module: $id_module
            ){
                id
            }
        }`,
        variables: {
            code, name, id_module
        }
    }),

    updateCategory: (client, {id, code, name, id_module}) => client.mutate({
        mutation: gql`mutation updateCategory(
            $id  : String
            $code: String
            $name: String
            $id_module: String
        ){
            updateCategory(
                id  : $id
                code: $code
                name: $name
                id_module: $id_module
            ){
                id
            }
        }`,
        variables: {
            id, code, name, id_module
        }
    }),

    statusCategory: (client, {id, enable}) => client.mutate({
        mutation: gql`mutation statusCategory(
                $id:     String
                $enable: Boolean
            ){
                statusCategory(
                    id:     $id
                    enable: $enable
                )
                {
                    id
                }
            }
        `,
        variables: {
            id, enable
        }
    })
}

export default CategorysModel