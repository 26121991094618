import gql from "graphql-tag"

const Diario = {
    updateOrCreate: (client, {
        id, fecha, medio_pago, constancia, provisiones, destinos, glosa_provision
    }, recursos) => client.mutate({
        mutation: gql`mutation createAsientoContable($id: String, $fecha: String!, $medio_pago: String, $constancia: String, $provisiones: JSON!, $destinos: JSON!, $glosa_provision: String!) {
                createAsientoContable(id: $id, fecha: $fecha, medio_pago: $medio_pago, constancia: $constancia, provisiones: $provisiones, destinos: $destinos, glosa_provision: $glosa_provision) {
                    ${recursos}
                  }
                }`,
        variables: {id, fecha, medio_pago, constancia, provisiones, destinos, glosa_provision}
    }),
    updateOrCreateInvInicial: (client, {id, fecha, provisiones, glosa_provision}, recursos) => client.mutate({
        mutation: gql`mutation createAsientoInvInicial($id: String, $fecha: String!, $provisiones: JSON!, $glosa_provision: String!) {
                createAsientoInvInicial(id: $id, fecha: $fecha, provisiones: $provisiones, glosa_provision: $glosa_provision) {
                    ${recursos}
                  }
                }`,
        variables: {id, fecha, provisiones, glosa_provision}
    }),
    updateAsientoContable: (client, asiento, recursos) => client.mutate({
        mutation: gql`mutation updateAsientoContable($id: String!, $update: JSON!){
                  updateAsientoContable(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id: asiento.id,
            update: {...asiento}
        }
    }),
    updateAsientoInvInicial: (client, asiento, recursos) => client.mutate({
        mutation: gql`mutation updateAsientoInvInicial($id: String!, $update: JSON!){
                  updateAsientoInvInicial(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id: asiento.id,
            update: {...asiento}
        }
    }),
    createAsientoPlanilla: (client, {id, fecha, provisiones, destinos, glosa_provision}, recursos) => client.mutate({
        mutation: gql`mutation createAsientoPlanilla($id: String, $fecha: String!, $provisiones: JSON!, $destinos: JSON!, $glosa_provision: String!) {
                createAsientoPlanilla(id: $id, fecha: $fecha, provisiones: $provisiones, destinos: $destinos, glosa_provision: $glosa_provision) {
                    ${recursos}
                  }
                }`,
        variables: {id, fecha, provisiones, destinos, glosa_provision}
    }),
    updateAsientoPlanilla: (client, asiento, recursos) => client.mutate({
        mutation: gql`mutation updateAsientoPlanilla($id: String!, $update: JSON!){
                  updateAsientoPlanilla(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id: asiento.id,
            update: {...asiento}
        }
    }),
    updateAsientoCierre: (client, asiento, recursos) => client.mutate({
        mutation: gql`mutation updateAsientoCierre($id: String!, $update: JSON!){
                  updateAsientoCierre(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id: asiento.id,
            update: {...asiento}
        }
    }),
    deleteAsiento: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteAsiento($id: String!){
                  deleteAsiento(id: $id) {
                      ${recursos}
                    }
                  }`,
        variables: {id}
    }),
    updateAsientoDestinoContable: (client, asiento, recursos) => client.mutate({
        mutation: gql`mutation updateAsientoDestinoContable($id: String!, $update: JSON!){
                  updateAsientoDestinoContable(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {id: asiento.id, update: {...asiento}}
    })
}

export default Diario