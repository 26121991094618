import gql from 'graphql-tag'

const DetalleCapituloVideoModel = {
    getById: (client, id, recursos) => client.query({
        query: gql`query detalleCapituloVideoById($id: String){
              detalleCapituloVideoById(id: $id){
                    ${recursos}
              }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    })
}
export default DetalleCapituloVideoModel