import gql from 'graphql-tag'

const PersonaModel = {
    create: (client, {
        numero, razon_social, direccion, departamento, provincia, distrito, ubigeo, estado, condicion,
        documento_id, observacion, email, telefono, tipo, is_default, is_trabajador
    }, recursos) => client.mutate({
        mutation: gql`mutation createPersona(
                        $numero: String!,
                        $razon_social: String!,
                        $direccion: String,
                        $departamento: String,
                        $provincia: String,
                        $distrito: String,
                        $ubigeo: String,
                        $estado: String,
                        $condicion: String,
                        $documento_id: String!,
                        $observacion: String,
                        $email: String,
                        $telefono: String,
                        $tipo: String!,
                        $is_default: Boolean,
                        $is_trabajador: Boolean
                     ) {
                        createPersona(
                        numero: $numero,
                        razon_social: $razon_social,
                        direccion: $direccion,
                        departamento: $departamento,
                        provincia: $provincia,
                        distrito: $distrito,
                        ubigeo: $ubigeo,
                        estado: $estado,
                        condicion: $condicion,
                        documento_id: $documento_id,
                        observacion: $observacion,
                        email: $email,
                        telefono: $telefono,
                        tipo: $tipo,
                        is_default: $is_default,
                        is_trabajador: $is_trabajador
                        ) {
                        ${recursos}
                      }
                    }`,
        variables: {
            numero, razon_social, direccion, departamento, provincia, distrito, ubigeo, estado, condicion,
            documento_id, observacion, email, telefono, tipo, is_default, is_trabajador
        }
    }),
    getAll: (client, tipo, recursos) => client.query({
        query: gql`query personasByTipo($tipo: String!){
                personas(tipo: $tipo) {
                      ${recursos}
                  }
              }`,
        variables: {
            tipo
        },
        fetchPolicy: 'no-cache'
    }),
    getLimit: (client, {tipo, limite}, recursos) => client.query({
        query: gql`query personasByTipo($tipo: String!, $limite: JSON){
                personas(tipo: $tipo, limite: $limite) {
                      ${recursos}
                  }
              }`,
        variables: {
            tipo, limite
        },
        fetchPolicy: 'no-cache'
    }),
    countPerson: (client, tipo, recursos) => client.query({
        query: gql`query contarPersonas($tipo: String!){
                contarPersonas(tipo: $tipo) {
                      ${recursos}
                  }
              }`,
        variables: {
            tipo
        },
        fetchPolicy: 'no-cache'
    }),
    getByParam: (client, {param, tipo}, recursos) => client.query({
        query: gql`query personasByParam($param: String!, $tipo: String){
                personasByParam(param: $param, tipo: $tipo) {
                      ${recursos}
                  }
              }`,
        variables: {
            param, tipo
        },
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query persona($id: String!){
                persona(id: $id) {
                      ${recursos}
                  }
              }`,
        variables: {
            id
        },
        fetchPolicy: 'no-cache'
    }),
    update: (client, {
        id, numero, razon_social, direccion, departamento, provincia, distrito, ubigeo, estado, condicion, documento_id,
        observacion, email, telefono, is_default, is_trabajador
    }, recursos) => client.mutate({
        mutation: gql`mutation updatePersona($id: String!, $update: JSON!){
                  updatePersona(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id,
            update: {
                numero, razon_social, direccion, departamento, provincia, distrito, ubigeo, estado, condicion,
                documento_id, observacion, email, telefono, is_default, is_trabajador
            }
        }
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deletedPersona($id: String!){
                  deletedPersona(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id
        }
    }),
    activar: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activarPersona($id: String!){
                  activarPersona(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id
        }
    }),
    validarClientes: (client, clientes, recursos) => client.query({
        query: gql`query validarCliente($clientes: JSON!){
            validarCliente(clientes: $clientes){
            ${recursos}
            }
        }`,
        variables: {clientes},
        fetchPolicy: 'no-cache'
    }),
    personaByNumero: (client, {numero, tipo}, recursos) => client.query({
        query: gql`query personaByNumero($numero: String, $tipo: String){
        personaByNumero(numero: $numero, tipo: $tipo){${recursos}}
        }`,
        variables: {numero, tipo},
        fetchPolicy: 'no-cache'
    }),
    createClientesMasivos: (client, clientes, recursos) => client.mutate({
        mutation: gql`mutation crearClientesMasivos($clientes: JSON!){
                  crearClientesMasivos(clientes: $clientes) {
                    ${recursos}
                  }
                }`,
        variables: {clientes},
        fetchPolicy: 'no-cache'
    }),
    excelPerProvTrans: (client, tipo, recursos) => client.query({
        query: gql`query excelPerProvTrans($tipo: String!){
                excelPerProvTrans(tipo: $tipo) {
                      ${recursos}
                  }
              }`,
        variables: {
            tipo
        },
        fetchPolicy: 'no-cache'
    })
}

export default PersonaModel