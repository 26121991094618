import gql from 'graphql-tag'

const PackModel = {
    createPack: (client, {code, description, duration, price, plan, id_service}) => client.mutate({
        mutation: gql`mutation createPack(
            $code           :String
            $description    :String
            $duration       :String
            $price          :Float
            $plan           :String
            $id_service     :String
        ){
            createPack(
                code        : $code
                description : $description
                duration    : $duration
                price       : $price
                plan        : $plan
                id_service  : $id_service
                
            ){
                id
            }
        }`,
        variables: {
            code, description, duration, price, plan, id_service
        }
    }),

    updatePack: (client, {id, code, description, duration, price, plan, id_service}) => client.mutate({
        mutation: gql`mutation updatePack(
            $id             :String
            $code           :String
            $description    :String
            $duration       :String
            $price          :Float
            $plan           :String
            $id_service     :String
        ){
            updatePack(
                id          : $id
                code        : $code
                description : $description
                duration    : $duration
                price       : $price
                plan        : $plan
                id_service  : $id_service
            ){
                id
            }
        }`,
        variables: {
            id, code, description, duration, price, plan, id_service
        }
    }),

    statusPack: (client, {id, enable}) => client.mutate({
        mutation: gql`mutation statusPack(
                $id:     String
                $enable: Boolean
            ){
                statusPack(
                    id:     $id
                    enable: $enable
                )
                {
                    id
                }
            }
        `,
        variables: {
            id, enable
        }
    })
}

export default PackModel