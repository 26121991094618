import gql from 'graphql-tag'


const CategoriaModel = {
    create: (client, {codigo, nombre}, recursos) => client.mutate({
        mutation: gql`mutation createCategoria(
                        $codigo: String!,
                        $nombre: String!, 
                        $isBody: Boolean                      
                    ){
                    createCategoria(
                    codigo: $codigo,
                    nombre: $nombre,
                    isBody: $isBody
                    ){
                    ${recursos}
                    }
                    }`,
        variables: {
            codigo,
            nombre,
            isBody: true
        }
    }),
    getAll: (client, recursos) => client.query({
        query: gql`query categorias($isBody: Boolean){
              categorias(isBody: $isBody) {
                ${recursos}
              }
            }`,
        variables: {isBody: true},
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) =>
        client.query({
            query: gql`query categoria($id:String!){
        categoria(id: $id){
                   ${recursos}
                }
        }`,
            variables: {
                id
            },
            fetchPolicy: 'no-cache'
        }),
    update: (client, {id, nombre, codigo, local_id}, recursos) => client.mutate({
        mutation: gql`mutation updateCategoria($id: String!, $update: JSON!, $isBody: Boolean){
                updateCategoria(id: $id, update: $update, isBody: $isBody){
                ${recursos}
                }
            }`,
        variables: {
            id,
            update: {
                nombre, local_id, codigo
            }, isBody: true
        }
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteCategoria($id: String!){
        deleteCategoria(id: $id){
            ${recursos}
             }
        }`,
        variables: {
            id: id
        }
    }),
    activate: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activateCategoria($id: String!){
        activateCategoria(id: $id){
            ${recursos}
             }
        }`,
        variables: {
            id: id
        }
    }),
    validarCategorias: (client, {categorias, isModelo}, recursos) => client.query({
        query: gql`query validarCategorias($categorias: JSON!, $isModelo: Boolean, $isBody: Boolean){
            validarCategorias(categorias: $categorias, isModelo: $isModelo, isBody: $isBody){
            ${recursos}
            }
        }`,
        variables: {categorias, isModelo, isBody: true},
        fetchPolicy: 'no-cache'
    }),
    categoriasByCodigo: (client, {codigo, isModelo}, recursos) => client.query({
        query: gql`query categoriasByCodigo($codigo: String, $isModelo: Boolean, $isBody: Boolean){
        categoriasByCodigo(codigo: $codigo, isModelo: $isModelo, isBody: $isBody){
        ${recursos}
        }
        }`,
        variables: {codigo, isModelo, isBody: true},
        fetchPolicy: 'no-cache'
    }),
    createCategoriasMasivos: (client, {categorias, isModelo}, recursos) => client.mutate({
        mutation: gql`mutation categoriaMasivo($categorias: JSON!, $isModelo: Boolean){
                  categoriaMasivo(categorias: $categorias, isModelo: $isModelo) {
                    ${recursos}
                  }
                }`,
        variables: {categorias, isModelo},
        fetchPolicy: 'no-cache'
    }),
    excelCategorias: (client, recursos) => client.query({
        query: gql`{
              excelCategorias {
                ${recursos}
              }
            }`,
        fetchPolicy: 'no-cache'
    }),


}
export default CategoriaModel