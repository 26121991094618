import gql from 'graphql-tag'

const MultimediaDrive = {
    FilesTipo: (client, tipo, recursos) => client.query({
        query: gql`query filesByTipo($tipo: String){
            filesByTipo(tipo:$tipo){
            ${recursos}
            }
        }`,
        variables: {tipo},
        fetchPolicy: 'no-cache'
    }),
    FilesNoTipo: (client, tipo, recursos) => client.query({
        query: gql`query filesExcludeTipo($tipo: String){
            filesExcludeTipo(tipo:$tipo){
            ${recursos}
            }
        }`,
        variables: {tipo},
        fetchPolicy: 'no-cache'
    }),
    GetElementsDrive: (client) => client.query({
        query: gql`{
              getElementsDrive{
                success
              }  
        }`,
        fetchPolicy: "no-cache"
    }),
    create: (client, {nombre, descripcion, tipo, recurso_id, name_resources}, recursos) => client.mutate({
        mutation: gql`mutation createMultimedia($nombre: String, $descripcion: String, $tipo:String,$recurso_id: String,
        $name_resources: String){
            createMultimedia(nombre: $nombre, descripcion: $descripcion, 
            tipo: $tipo, recurso_id: $recurso_id, name_resources:$name_resources){
                ${recursos}            
            }
        }`,
        variables: {
            nombre, descripcion, tipo, recurso_id, name_resources
        },
        fetchPolicy: "no-cache"
    }),
    update: (client, {
        id, nombre, descripcion, recursos, id_file, contenido_html, isHtml = false
    }, resources) => client.mutate({
        mutation: gql`mutation updateMultimedia($id: String, $update: JSON,$isHtml: Boolean ){
                   updateMultimedia(id: $id, update: $update, isHtml: $isHtml){
                       ${resources}       
            }
        }`,
        variables: {
            id, update: {nombre, descripcion, recursos, id_file, contenido_html}, isHtml
        },
        fetchPolicy: "no-cache"
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query multimediaById($id: String){
                multimediaById(id: $id){
                ${recursos}
                }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    download: (client, id) => client.query({
        query: gql`query downloadDrive($id: String){
               downloadDrive(id: $id){
                   data
               } 
        }`
        ,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    createHtml: (client, {nombre, descripcion, tipo, contenido_html, recursos}, resources) => client.mutate({
        mutation: gql` mutation createHTML($nombre: String,$descripcion: String, $tipo: String,$contenido_html: String, $recursos: JSON){
                    createHTML(nombre: $nombre,descripcion: $descripcion, tipo: $tipo,contenido_html: $contenido_html, recursos: $recursos){
                        ${resources}
                    }
        }`,
        variables: {
            nombre, descripcion, tipo, contenido_html, recursos
        },
        fetchPolicy: "no-cache"
    })

}
export default MultimediaDrive