import gql from "graphql-tag"

const Api = {
    apiCuspp: (client, dni, recursos) => {
        return client.query({
            query: gql`query apicuspp(
                $dni: String!
            ){
                apicuspp(
                    dni: $dni
                ) {
                    ${recursos}
                }
            }`,
            variables: {
                dni
            },
            fetchPolicy: "no-cache"
        })
    },
    apiRest: (client, {path, json}, recursos) => {
        return client.query({
            query: gql`query getApiRest($path: String!, $json: JSON!){
                apirest(path: $path, json: $json) {
                    ${recursos}
                }
            }`,
            variables: {
                path, json
            },
            fetchPolicy: "no-cache"
        })
    },
    apiFact: (client, {path, json}, recursos) => client.query({
        query: gql`query apifact($path: String!, $json: JSON!) {
                apifact(path: $path, json: $json) {
                    ${recursos}
                }
            }`,
        variables: {
            path, json
        },
        fetchPolicy: "no-cache"
    }),
    apiafp: (client, {periode}, recursos) => {
        return client.query({
            query: gql`query apiafp($periode: String){
                apiafp(periode: $periode) {
                    ${recursos}
                }
            }`,
            variables: {
                periode: periode
            },
            fetchPolicy: "no-cache"
        })
    },
    apiLicencias: (client, dni) => client.query({
        query: gql` query apiLicencias($dni: String){
                 apiLicencias(dni: $dni){
                     result   
              }   
        }`,
        variables: {dni}
    })
}

export default Api
