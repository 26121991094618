import gql from 'graphql-tag'

const FactureBuyModel = {
    createFactureBuy: (client, {id_user, json1}) => client.mutate({
        mutation: gql`mutation createFactureBuy(
            $id_user: String
            $json1  : JSON
        ){
            createFactureBuy(
                id_user: $id_user
                json1  : $json1
            ){
                id
            }
        }`,
        variables: {
            id_user, json1
        }
    }),
    createFactureBuyUser: (client, id_user, user) => client.mutate({
        mutation: gql`mutation createFactureBuyUser(
            $id_user: String
            $user: String
        ){
            createFactureBuyUser(
                id_user: $id_user
                user: $user
            ){
                id
            }
        }`,
        variables: {
            id_user, user
        }
    })
}

export default FactureBuyModel