import React from "react"
import {Card, CardBody} from "reactstrap"

import ComprasVentas from "./Components/ComprasVentas"
import CronogramaObligaciones from "./Components/CronogramaObligaciones"

const Contable = () => {

    return (
        <Card className='main-card'>
            <CardBody>
                <ComprasVentas/>
                <CronogramaObligaciones/>
            </CardBody>
        </Card>
    )
}

export default Contable