import gql from 'graphql-tag'


const RemOtrosEmpleadores = {
    create: (client, {id_empleado, periodo, monto}, recursos) => client.mutate({
        mutation: gql` mutation createRemOtrosEmpleados($id_empleado: String, $periodo: String, $monto: Float){
                        createRemOtrosEmpleados(id_empleado: $id_empleado, periodo: $periodo, monto: $monto){
                        ${recursos}            
            }
        }`,
        variables: {id_empleado, periodo, monto},
        fetchPolicy: 'no-cache'
    }),
    update: (client, {id, monto, deleted_at}, recursos) => client.mutate({
        mutation: gql`mutation updateRemOtrosEmpleadores($id: String, $update: JSON){
        updateRemOtrosEmpleadores(id: $id, update: $update){
                ${recursos}
            }
        }`,
        variables: {id, update: {monto, deleted_at}},
        fetchPolicy: 'no-cache'
    }),
    getEmpleadoPeriodo: (client, {id_empleado, periodo}, recursos) => client.query({
        query: gql`query remOtroByEmpleadoPeriodo($id_empleado: String, $periodo: String){
              remOtroByEmpleadoPeriodo(id_empleado: $id_empleado, periodo: $periodo){
                ${recursos}
              }  
        }`,
        variables: {id_empleado, periodo},
        fetchPolicy: 'no-cache'
    })
}
export default RemOtrosEmpleadores