import gql from 'graphql-tag'

const MesaModel = {
    create: (client, {codigo, descripcion, zona_id}, recursos) => client.mutate({
        mutation: gql`mutation createMesa($codigo: String,$descripcion: String, $zona_id: String)
                        {
                        createMesa(codigo: $codigo, descripcion: $descripcion, zona_id: $zona_id){
                        ${recursos}
                        } 
                        }`,
        variables: {
            codigo, descripcion, zona_id
        }
    }),
    update: (client, {id, codigo, descripcion, zona_id}, recursos) => client.mutate({
        mutation: gql`mutation updateMesa($id: String!,$update: JSON!){
        updateMesa(id: $id, update: $update){
        ${recursos}
        }
        }`,
        variables: {
            id,
            update: {
                codigo, descripcion, zona_id
            }
        }
    }),
    activate: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activarMesa($id: String!){
        activarMesa(id: $id){
        ${recursos}
        }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteMesa($id: String!){
        deleteMesa(id: $id){
        ${recursos}
             }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query mesaById($id: String!){
                   mesaById(id: $id){
                   ${recursos}
                   }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    getAllZonas: (client, zona, recursos) => client.query({
        query: gql`
        query mesas($zona: String!){
             mesas (zona: $zona){
                ${recursos}
            }
        }`,
        variables: {zona},
        fetchPolicy: 'no-cache'
    }),
    getAllMesaLocal: (client, recursos) => client.query({
        query: gql`{
            mesasbyLocal{
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    updateMesaByZona: (client, zona_id, recursos) => client.subscribe({
        query: gql`
        subscription updateMesaByZona($zona_id: String!) {
          updateMesaByZona(zona_id: $zona_id) {
            ${recursos}
          }
        }`,
        variables: {zona_id}
    })
}

export default MesaModel