import React, {Fragment, useEffect, useState} from 'react'
import { VerticalTimeline, VerticalTimelineElement }                    from 'react-vertical-timeline-component'
import { faUserTimes }                                                  from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon }                                              from '@fortawesome/react-fontawesome'
import PerfectScrollbar                                                 from 'react-perfect-scrollbar'
import Slider                                                           from 'react-slick'
import {Button, Col, Modal, ModalBody, ModalFooter, Row, ModalHeader, Card, CardBody} from 'reactstrap'
import moment                                                           from 'moment'

import bg1           from "../../assets/utils/images/originals/bg1.jpg"
import bg3           from "../../assets/utils/images/originals/bg3.jpg"
import bg2           from "../../assets/utils/images/originals/bg2.jpg"
import {Reclamation} from "../../Components/Models"
import {getNavId}    from "../../utils/scripts"
import {MyLabel}     from "../../Components"
import {useAuth}     from "../../Context"
import {useInput}    from "../../hooks"
import './sigCanvas.css'

const AppMain = () => {
    const {id: idNav} = getNavId(),
            {client, toast, removeAllToasts} = useAuth(),
            [show, setShow]                  = useState(false),
            [data, setData]                  = useState({}),
            [details, setDetails]            = useState([]),
            [htmlModal, setHtmlModal]        = useState(<></>),
            settings = {
                dots: true,
                infinite: true,
                speed: 500,
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                initialSlide: 0,
                autoplay: true,
                adaptiveHeight: true
            },
            [nro, inputNro] = useInput({
                placeholder: 'Nombres',
                typeState: 'number'
            }),
            [phone, inputPhone] = useInput({
                placeholder: 'Telefono o Celular',
                typeState: 'number'
            }),
            Search = async() => {
                Reclamation.searchReclamation(client, { nro, phone},'id, status, description, created_at, updated_at') .then(({data}) => {
                    removeAllToasts()
                    setData({})
                    try {
                        if(!!data.searchReclamation.length > 0){
                            toast.success("Reclamo Encontrado", {autoClose: 3000})
                            setDetails(data.searchReclamation)
                            Reclamation.filterReclamation(client,  nro, phone,'namefull, address, email, phone, nro, opcion0, opcion1, option2, dates') .then(({data}) => {
                                setData(data.filterReclamation)
                            }).catch(() => {
                                removeAllToasts()
                                toast.warning("No se Encontro ningun Registro", {autoClose: 5000})
                            })
                            return setShow(true)
                        }
                        throw "Error"
                    } catch {
                        return toast.warning("No se Encontro ningun Registro", {autoClose: 5000})
                    }
                }).catch(() => {
                    removeAllToasts()
                    toast.warning("No se Encontro ningun Registro", {autoClose: 5000})
                })
            }
        useEffect(() => {
            if(data !== {} && data.opcion1){
                setHtmlModal(
                    <Row>
                        <Col md={6}>
                            <MyLabel name='RECLAMANTE : '/>
                            <input type={'text'} defaultValue={data.namefull} className={"form-control"} disabled/>
                        </Col>
                        <Col md={6}>
                            <MyLabel name='CORREO : '/>
                            <input type={'text'} defaultValue={data.email} className={"form-control"} disabled/>
                        </Col>
                        <Col md={4}>
                            <MyLabel name={`${data.opcion0} : `}/>
                            <input type={'text'} defaultValue={data.nro} className={"form-control"} disabled/>
                        </Col>
                        <Col md={5}>
                            <MyLabel name='TIPO DE RECLAMO : '/>
                            <input type={'text'} defaultValue={`${data.opcion1}/${data.option2}`} className={"form-control"} disabled/>
                        </Col>
                        <Col md={3}>
                            <MyLabel name='TELÉFONO : '/>
                            <input type={'text'} defaultValue={data.phone} className={"form-control"} disabled/>
                        </Col>
                    </Row>
                )
            }
        },[data])
    return (
        <Fragment>
            <div className="h-100">
                <Row className="h-100 no-gutters">
                    <Col lg="7" md="12"
                         className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
                        <Col lg="11" md="11" sm="12" className="mx-auto app-login-box">
                            <div className="text-center">
                                <img src="https://app.municallao.gob.pe/secretaria/resource/images/libro.png" alt="Logo"/>
                            </div>
                            <Row>
                                <Col md={6}>
                                    <MyLabel name='N° DEL RECLAMO' requerid/>
                                    {inputNro}
                                </Col>
                                <Col md={6}>
                                    <MyLabel name='CELULAR DEL RECLAMO' requerid/>
                                    {inputPhone}
                                </Col>
                                <Col md={12}> <br/> </Col>
                                <Col md={12}>
                                    <Button style={{width: '100%'}} color='primary' onClick={() => Search()}>Buscar</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                    <Col lg="5" className="d-none d-lg-block">
                        <div className="slider-light">
                            <Slider  {...settings}>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div className="slide-img-bg"
                                         style={{
                                             backgroundImage: 'url(' + bg1 + ')'
                                         }}/>
                                    <div className="slider-content">
                                        <h3>Consulta de Validez de CPE</h3>
                                        <p>
                                            Consulte la validez de boletas, facturas y sus respectivas notas
                                            electrónicas.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div className="slide-img-bg"
                                         style={{
                                             backgroundImage: 'url(' + bg3 + ')'
                                         }}/>
                                    <div className="slider-content">
                                        <h3>Pago masivo de detracciones con NPD</h3>
                                        <p>
                                            Operaciones SOL para pago masivo.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div className="slide-img-bg opacity-6"
                                         style={{
                                             backgroundImage: 'url(' + bg2 + ')'
                                         }}/>
                                    <div className="slider-content">
                                        <h3>Consulta DNI, RUC y EsSalud</h3>
                                        <p>
                                            Consultas de reniec, sunat y essalud sin capcha.
                                        </p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal isOpen={show} toggle={() => show} keyboard={false} size={"lg"} backdrop={true}>
                <ModalHeader style={{border: '0px solid', placeContent: 'center'}}>
                    <div> SEGUIMIENTO DE SU RECLAMO </div>
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardBody>
                            <strong style={{margin:'1px', marginLeft: '1px', color: 'red'}}>
                                <i className="lnr-user text-danger"/> DATOS DEL RECLAMANTE
                            </strong>
                            {htmlModal}
                        </CardBody>

                        <hr/>
                        <strong style={{margin:'5px', marginLeft: '20px', color: 'red'}}>
                            <i className="lnr-flag text-danger"/> ESTADO ACTUAL DE SU RECLAMO
                        </strong>
                        <Row>
                            <Col md={2}/>
                            <Col md={10}>
                                <PerfectScrollbar>
                                    <VerticalTimeline className="vertical-time-icons" layout="1-column">
                                        {
                                            details.map(item => {
                                                return (
                                                    <>
                                                        {
                                                            item.status === 'INICIO DEL RECLAMO' ? (
                                                                <VerticalTimelineElement
                                                                    className="vertical-timeline-item"
                                                                    icon={<div className="timeline-icon border-primary"><i className="lnr-spell-check text-primary"/></div>}
                                                                >
                                                                    <h4 className="timeline-title"> <FontAwesomeIcon icon={faUserTimes}/> {item.status} {item.updated_at ? moment(item.updated_at).format('DD/MM/YYYY hh:MM:SS A') : moment(item.created_at).format('DD/MM/YYYY hh:MM:SS A') }</h4>
                                                                    <p> {item.description} </p>
                                                                </VerticalTimelineElement>
                                                            ) : (
                                                                item.status === 'PROCESANDO EL RECLAMO' ? (
                                                                    <VerticalTimelineElement
                                                                        className="vertical-timeline-item"
                                                                        icon={<div className="timeline-icon border-warning"> <i className="lnr-cog fa-spin text-warning"/> </div>}
                                                                    >
                                                                        <h4 className="timeline-title"> <FontAwesomeIcon icon={faUserTimes}/> {item.status} {item.updated_at ? moment(item.updated_at).format('DD/MM/YYYY hh:MM:SS A') : moment(item.created_at).format('DD/MM/YYYY hh:MM:SS A') }</h4>
                                                                        <p> {item.description} </p>
                                                                    </VerticalTimelineElement>
                                                                ) : (
                                                                    item.status === 'VALIDACIÓN DEL RECLAMO' ? (
                                                                        <VerticalTimelineElement
                                                                            className="vertical-timeline-item"
                                                                            icon={<div className="timeline-icon border-success"><i className="lnr-eye text-success"/> </div>}
                                                                        >
                                                                            <h4 className="timeline-title"> <FontAwesomeIcon icon={faUserTimes}/> {item.status} {item.updated_at ? moment(item.updated_at).format('DD/MM/YYYY hh:MM:SS A') : moment(item.created_at).format('DD/MM/YYYY hh:MM:SS A') }</h4>
                                                                            <p> {item.description} </p>
                                                                        </VerticalTimelineElement>
                                                                    ) : (
                                                                        item.status === 'PRESENTACIÓN DE DESCARGOS' ? (
                                                                            <VerticalTimelineElement
                                                                                className="vertical-timeline-item"
                                                                                icon={<div className="timeline-icon border-dark"> <i className="lnr-license text-dark"/></div>}
                                                                            >
                                                                                <h4 className="timeline-title"> <FontAwesomeIcon icon={faUserTimes}/> {item.status} {item.updated_at ? moment(item.updated_at).format('DD/MM/YYYY hh:MM:SS A') : moment(item.created_at).format('DD/MM/YYYY hh:MM:SS A') }</h4>
                                                                                <p> {item.description} </p>
                                                                            </VerticalTimelineElement>
                                                                        ) : (
                                                                            item.status === 'CONTACTO AL CLIENTE' ? (
                                                                                <VerticalTimelineElement
                                                                                    className="vertical-timeline-item"
                                                                                    icon={<div className="timeline-icon border-info"> <i className="lnr-phone-handset text-info"/></div>}
                                                                                >
                                                                                    <h4 className="timeline-title"> <FontAwesomeIcon icon={faUserTimes}/> {item.status} {item.updated_at ? moment(item.updated_at).format('DD/MM/YYYY hh:MM:SS A') : moment(item.created_at).format('DD/MM/YYYY hh:MM:SS A') }</h4>
                                                                                    <p> {item.description} </p>
                                                                                </VerticalTimelineElement>
                                                                            ) : (
                                                                                <VerticalTimelineElement
                                                                                    className="vertical-timeline-item"
                                                                                    icon={<div className="timeline-icon bg-danger border-danger"><i className="lnr-checkmark-circle text-white"/></div>}
                                                                                >
                                                                                    <h4 className="timeline-title"> <FontAwesomeIcon icon={faUserTimes}/> {item.status} {item.updated_at ? moment(item.updated_at).format('DD/MM/YYYY hh:MM:SS A') : moment(item.created_at).format('DD/MM/YYYY hh:MM:SS A') }</h4>
                                                                                    <p> {item.description} </p>
                                                                                </VerticalTimelineElement>
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )

                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </VerticalTimeline>
                                </PerfectScrollbar>
                            </Col>
                        </Row>
                    </Card>
                </ModalBody>
                <ModalFooter style={{border: '0px solid'}}>
                    <Button color="danger" style={{width: '100%'}} onClick={() => setShow(!show)}> Cerrar </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default AppMain