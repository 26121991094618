import gql from 'graphql-tag'

const ServiceModel = {
    createService: (client, {code, name, category, permits}) => client.mutate({
        mutation: gql`mutation createService(
            $code    : String
            $name    : String
            $category: String
            $permits : JSON
        ){
            createService(
                code    : $code
                name    : $name
                category: $category
                permits : $permits
            ){
                id
            }
        }`,
        variables: {
            code, name, category, permits
        }
    }),

    updateService: (client, {id, code, name, category, permits}) => client.mutate({
        mutation: gql`mutation updateService(
            $id      : String
            $code    : String
            $name    : String
            $category: String
            $permits : JSON
        ){
            updateService(
                id      : $id
                code    : $code
                name    : $name
                category: $category
                permits : $permits
            ){
                id
            }
        }`,
        variables: {
            id, code, name, category, permits
        }
    }),

    statusService: (client, {id, enable}) => client.mutate({
        mutation: gql`mutation statusService(
                $id:     String
                $enable: Boolean
            ){
                statusService(
                    id:     $id
                    enable: $enable
                )
                {
                    id
                }
            }
        `,
        variables: {
            id, enable
        }
    })
}

export default ServiceModel