import gql from 'graphql-tag'

const ModeloModel = {
    create: (client, {codigo, nombre, oferta, descuento}, recursos) => client.mutate({
        mutation: gql`mutation createModelo($codigo: String!, $nombre: String!, $oferta: Boolean!, $descuento: Float!) {
            createModelo(codigo: $codigo, nombre: $nombre, oferta: $oferta, descuento: $descuento) {
                ${recursos}
            }
        }`,
        variables: {codigo, nombre, oferta, descuento: Number(descuento)}
    }),
    getAll: (client, recursos) => client.query({
        query: gql`{
              modelos {
                ${recursos}
              }
            }`,
        fetchPolicy: 'no-cache'
    }),
    getAllWithDeleted: (client, recursos) => client.query({
        query: gql`{
              modelosWithDeleted {
                ${recursos}
              }
            }`,
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) =>
        client.query({
            query: gql`query modelo($id:String!) {
                modelo(id: $id) {
                    ${recursos}
                }
            }`,
            variables: {id},
            fetchPolicy: 'no-cache'
        }),
    update: (client, {id, nombre, codigo, oferta, descuento, local_id}, recursos) => client.mutate({
        mutation: gql`mutation updateModelo($id: String!, $update: JSON!){
                updateModelo(id: $id, update: $update){
                ${recursos}
                }
            }`,
        variables: {
            id,
            update: {nombre, codigo, oferta, descuento: Number(descuento)}
        }
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteModelo($id: String!){
            deleteModelo(id: $id){
                ${recursos}
             }
        }`,
        variables: {id}
    }),
    activate: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activateModelo($id: String!){
            activateModelo(id: $id){
                ${recursos}
             }
        }`,
        variables: {id}
    }),
}

export default ModeloModel