import gql from 'graphql-tag'

const CursoModel = {
    create: (client, {nombre, codigo, descripcion, modulo_id, includes_capitulos, videos}, recursos) => client.mutate({
        mutation: gql`mutation createCurso($codigo: String,$nombre:String,$descripcion: String,
        $modulo_id: String, $includes_capitulos: Boolean, $videos: JSON) {
            createCurso(codigo: $codigo, nombre: $nombre, descripcion: $descripcion, modulo_id: $modulo_id, includes_capitulos: $includes_capitulos, videos: $videos){
                ${recursos}            
            }        
        }
        `,
        variables: {
            nombre, codigo, descripcion, modulo_id, includes_capitulos, videos
        }
    }),
    update: (client, {
        id, nombre, codigo, descripcion, modulo_id, includes_capitulos, videos
    }, recursos) => client.mutate({
        mutation: gql`mutation updateCurso($id: String,$update: JSON, $videos: JSON){
            updateCurso(id: $id, update: $update, videos:$videos){
                   ${recursos}
         }
        }
        `,
        variables: {
            id, update: {nombre, codigo, descripcion, modulo_id, includes_capitulos}, videos
        }
    }),
    getAllCursos: (client, recursos) => client.query({
        query: gql`{
           cursos{
                 ${recursos}          
           } 
          }
        `
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`
            query cursoById($id: String){
                  cursoById(id: $id){
                ${recursos}   
               }
            }
        `,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    getPrevNext: (client, {order, capitulo_id}) => client.query({
        query: gql`
            query getPrevNext($order: String, $capitulo_id: String){
                  getPrevNext(order: $order, capitulo_id: $capitulo_id){
                    data
                  }
            }
        `,
        variables: {order, capitulo_id},
        fetchPolicy: 'no-cache'
    })
}
export default CursoModel