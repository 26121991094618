import React, { Fragment, useState } from 'react'
import AsyncSelect from 'react-select/async'

import { customSelectStyles } from "../utils/constantes"

export default function useAsyncSelect({ initialState, optionsState, initialMessage, isClearable, modelo, isDisabled, onItemSelected = null, addItemProducto = null, inputRef = null }) {
    const [value, setValue] = useState(initialState ? initialState : '')
    const [options, setOptions] = useState(optionsState ? optionsState : [])
    const [invalid, setInvalid] = useState(false)
    const [message, setMesssage] = useState(initialMessage ? initialMessage : '')
    const [disabled, setDisabled] = useState(isDisabled ? isDisabled : false)

    let timer
    const promiseOptions = inputValue => new Promise(resolve => {
        clearTimeout(timer)
        let { client, Model, respuesta, table, getByParam } = modelo
        getByParam = getByParam ? getByParam : 'getByParam'
        const query = table ? { table, param: inputValue } : inputValue
        timer = setTimeout(() =>{
            if( addItemProducto !== null ) {
                resolve(Model
                    [getByParam](client, query, 'value, label, code, name,codigo_barras')
                        .then(response => {
                            const data = response.data[respuesta]
                            setOptions(data)
                            if(data.length === 1){
                                if(onItemSelected !== null){
                                    if(inputValue === data[0].codigo_barras){
                                        onItemSelected(data[0])
                                    }else if( inputValue === data[0].code){
                                        onItemSelected(data[0]);
                                    }
                                }
                            }
                            return data
                        })
                        .catch(({ message }) => console.log(message))
                    )
            }else{
                resolve(Model
                    [getByParam](client, query, 'value, label, code, name')
                        .then(response => {
                            const data = response.data[respuesta]
                            setOptions(data)
                            return data
                        })
                        .catch(({ message }) => console.log(message))
                    )
            }

        } , 500)
    })

    const selectElement = (
        <Fragment>
            {inputRef !== null ? (
                <AsyncSelect
                    ref={inputRef}
                    placeholder="Seleccionar..."
                    value={options.filter(element => element.value === value)}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                    isClearable={isClearable ? isClearable : false}
                    isDisabled={disabled ? disabled : false}
                    loadOptions={promiseOptions}
                    onChange={e => {
                        setValue(e ? e.value : '');
                        //addItemProducto();
                    }}
                    noOptionsMessage={() => 'Escriba para buscar...'}
                    defaultOptions={options}
                    styles={
                        invalid
                            ? {
                                ...customSelectStyles,
                                container: base => ({
                                    ...base,
                                    backgroundColor: '#dc3545',
                                    padding: 1,
                                    borderRadius: 3,
                                }),
                            }
                            : customSelectStyles
                    }
                />
            ) : (
                <AsyncSelect
                    placeholder="Seleccionar..."
                    value={options.filter(element => element.value === value)}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                    isClearable={isClearable ? isClearable : false}
                    isDisabled={disabled ? disabled : false}
                    loadOptions={promiseOptions}
                    onChange={e => {
                        setValue(e ? e.value : '');
                    }}
                    noOptionsMessage={() => 'Escriba para buscar...'}
                    defaultOptions={options}
                    styles={
                        invalid
                            ? {
                                ...customSelectStyles,
                                container: base => ({
                                    ...base,
                                    backgroundColor: '#dc3545',
                                    padding: 1,
                                    borderRadius: 3,
                                }),
                            }
                            : customSelectStyles
                    }
                />
            )}

            {invalid === true ? <small className="text-danger">{message}</small> : <></>}
        </Fragment>
    )

    return [value, selectElement, setValue, setInvalid, setOptions, setMesssage, invalid, setDisabled]
}