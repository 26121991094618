import gql from 'graphql-tag'

const CierreContableModel = {
    determinacionUapi: (client, recursos) => client.query({
        query: gql`{
              determinacionUapi {
                ${recursos}
              }
            }`,
        fetchPolicy: 'no-cache'
    }),
    distribucionRenta: (client, {
        uc, a_temporales, a_permanentes, d_temporales, d_permanentes, compensacion_perdidas
    }, recursos) => client.query({
        query: gql`query distribucionRenta($uc: Float, $a_temporales: Float, $a_permanentes: Float, $d_temporales: Float, $d_permanentes: Float, $compensacion_perdidas: Float) {
            distribucionRenta(uc: $uc, a_temporales: $a_temporales, a_permanentes: $a_permanentes, d_temporales: $d_temporales, d_permanentes: $d_permanentes, compensacion_perdidas: $compensacion_perdidas) {
                ${recursos}
            }
        }`,
        variables: {
            uc: Number(uc), a_temporales: Number(a_temporales), a_permanentes: Number(a_permanentes),
            d_temporales: Number(d_temporales), d_permanentes: Number(d_permanentes),
            compensacion_perdidas: Number(compensacion_perdidas)
        },
        fetchPolicy: 'no-cache'
    }),
    createCierreContable: (client, provisiones, recursos) => client.mutate({
        mutation: gql`mutation createCierreContable($provisiones: JSON!) {
                createCierreContable(provisiones: $provisiones) {
                    ${recursos}
                  }
                }`,
        variables: {provisiones}
    }),
    cuentasBalance: (client, recursos) => client.query({
        query: gql`{
              cuentasBalance {
                ${recursos}
              }
            }`,
        fetchPolicy: 'no-cache'
    }),
    createCuentasBalance: (client, provisiones, recursos) => client.mutate({
        mutation: gql`mutation createCuentasBalance($provisiones: JSON!) {
                createCuentasBalance(provisiones: $provisiones) {
                    ${recursos}
                  }
                }`,
        variables: {provisiones}
    }),
}

export default CierreContableModel