import React from 'react'
import {render} from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import {ToastProvider} from 'react-toast-notifications'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'

import * as serviceWorker from './serviceWorker'
import './assets/base.scss'
import Main from './Main'
import configureStore from './config/configureStore'

require('dotenv').config()

const store = configureStore()

const renderApp = Component => {
    render(
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_SITE_KEY}
            language="es-419"
            // useRecaptchaNet="[optional_boolean_value]"
            // useEnterprise="[optional_boolean_value]"
            scriptProps={{
                async: false, // optional, default to false,
                defer: false, // optional, default to false
                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                nonce: undefined // optional, default undefined
            }}
        >
            <Provider store={store}>
                <ToastProvider autoDismiss={true} transitionDuration={0}>
                    <BrowserRouter>
                        <Component/>
                    </BrowserRouter>
                </ToastProvider>
            </Provider>
        </GoogleReCaptchaProvider>,
        document.getElementById('root')
    )
}

renderApp(Main)

if (module.hot) {
    module.hot.accept('./Main', () => {
        const NextApp = require('./Main').default
        renderApp(NextApp)
    })
}

serviceWorker.unregister()