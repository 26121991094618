import gql from 'graphql-tag'

const CentroCosto = {
    create: (client, {codigo, nombre}, recursos) => client.mutate({
        mutation: gql`mutation createCentroCosto($codigo: String!, $nombre: String!) {
            createCentroCosto(codigo: $codigo, nombre: $nombre) {
                ${recursos}
            }
        }`,
        variables: {
            codigo, nombre
        }
    }),
    getAll: (client, withEliminated, recursos) => client.query({
        query: gql`query centroCostos($withEliminated: Boolean!) {
            centroCostos(withEliminated: $withEliminated) {
                  ${recursos}
              }
          }`,
        variables: {
            withEliminated
        },
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query centroCosto($id: String!) {
                centroCosto(id: $id) {
                      ${recursos}
                  }
              }`,
        variables: {
            id
        },
        fetchPolicy: 'no-cache'
    }),
    update: (client, {id, codigo, nombre}, recursos) => client.mutate({
        mutation: gql`mutation updateCentroCosto($id: String!, $update: JSON!){
                  updateCentroCosto(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id,
            update: {
                codigo, nombre
            }
        }
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteCentroCosto($id: String!){
                  deleteCentroCosto(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id
        }
    }),
    activar: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activarCentroCosto($id: String!){
                  activarCentroCosto(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id
        }
    }),
    getByCodigo: (client, codigo, recursos) => client.query({
        query: gql`query  centroCostosByCodigo ($codigo: String!){
        
        centroCostosByCodigo(codigo: $codigo) 
        {${recursos}}
        }`,
        variables: {
            codigo
        },
        fetchPolicy: 'no-cache'
    }),
    getByParam: (client, param, recursos) => client.query({
        query: gql`query pcgeByParam($param: String!){
                pcgeByParam(param: $param) {
                      ${recursos}
                  }
              }`,
        variables: {
            param
        },
        fetchPolicy: 'no-cache'
    }),

}

export default CentroCosto