import gql from "graphql-tag"

const Commission = {
    selectComission: (client, { send }, recursos) => client.query({
        query: gql`query selectComission(
            $empresa_id: String
        ){
            selectComission(
                empresa_id:  $empresa_id
            ) {
                ${recursos}
            }
        }`,
        variables: {
            empresa_id: send.empresa_id
        },
        fetchPolicy: "no-cache"
    }),

    filterComission: (client, { send }, recursos) => client.query({
        query: gql`query filterComission(
            $id: String
        ){
            filterComission(
                id:  $id
            ) {
                ${recursos}
            }
        }`,
        variables: {
            id: send.id
        },
        fetchPolicy: "no-cache"
    }),

    editComission: (client, { send }, recursos) => client.mutate({
        mutation: gql`mutation editComission(
            $id:         String,
            $habitat:    JSON,
            $prima:      JSON,
            $integra:    JSON,
            $profuturo:  JSON,
            $periode:    String
        ){
            editComission(
                id:         $id
                habitat:    $habitat,
                prima:      $prima,
                integra:    $integra,
                profuturo:  $profuturo,
                periode:    $periode
            ) {
                ${recursos}
            }
        }`,
        variables: {
            id:         send.id,
            habitat:    send.HABITAT,
            prima:      send.PRIMA,
            integra:    send.INTEGRA,
            profuturo:  send.PROFUTURO,
            periode:    send.periode
        }
    }),

    createComission: (client, {send}, recursos) => client.mutate({
        mutation: gql`mutation createComission(
            $empresa_id: String,
            $habitat:    JSON,
            $prima:      JSON,
            $integra:    JSON,
            $profuturo:  JSON,
            $periode:    String
        ){
            createComission(
                empresa_id: $empresa_id,
                habitat:    $habitat
                prima:      $prima
                integra:    $integra
                profuturo:  $profuturo
                periode:    $periode
            ){
                ${recursos}
            }
        }`,
        variables: {
            empresa_id: send.empresa_id,
            habitat:    send.HABITAT,
            prima:      send.PRIMA,
            integra:    send.INTEGRA,
            profuturo:  send.PROFUTURO,
            periode:    send.periode
        }
    }),

    existComission: (client, { send }, recursos) => client.query({
        query: gql`query existComission(
            $empresa_id: String
            $periode:    String
        ){
            existComission(
                empresa_id: $empresa_id
                periode:    $periode
            ) {
                ${recursos}
            }
        }`,
        variables: {
            empresa_id: send.empresa_id,
            periode: send.periode
        },
        fetchPolicy: "no-cache"
    }),
}

export default Commission