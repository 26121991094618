import gql from 'graphql-tag'

const LocalModel = {
    create: (client, {
        empresa_id, codigo_establecimiento, codigo_serie, direccion, departamento, provincia, distrito, ubigeo,
        correo, descripcion, telefono, codigo_sunat, enviar_xml, enviar_email, formato_pdf, diseno_pdf, width_ticket,
        incluir_guias_kardex, nombre_corto
    }, recursos) => client.mutate({
        mutation: gql`mutation createLocal(
                    $empresa_id: String!, 
                    $codigo_establecimiento: String, 
                    $codigo_serie: String!, 
                    $direccion: String!, 
                    $departamento: String!, 
                    $provincia: String!, 
                    $distrito: String!, 
                    $ubigeo: String, 
                    $correo: String, 
                    $descripcion: String, 
                    $nombre_corto: String, 
                    $telefono: String, 
                    $codigo_sunat: String!, 
                    $enviar_xml: Boolean!, 
                    $enviar_email: Boolean!, 
                    $formato_pdf: String!, 
                    $diseno_pdf: String!,
                    $width_ticket: String,
                    $incluir_guias_kardex: Boolean
                 ) {
                  createLocal(
                    empresa_id: $empresa_id, 
                    codigo_establecimiento: $codigo_establecimiento, 
                    codigo_serie: $codigo_serie, 
                    direccion: $direccion, 
                    departamento: $departamento, 
                    provincia: $provincia, 
                    distrito: $distrito, 
                    ubigeo: $ubigeo, 
                    correo: $correo, 
                    descripcion: $descripcion, 
                    nombre_corto: $nombre_corto, 
                    telefono: $telefono, 
                    codigo_sunat: $codigo_sunat, 
                    enviar_xml: $enviar_xml, 
                    enviar_email: $enviar_email, 
                    formato_pdf: $formato_pdf, 
                    diseno_pdf: $diseno_pdf,
                    width_ticket: $width_ticket,
                    incluir_guias_kardex: $incluir_guias_kardex
                    ) {
                    ${recursos}
                  }
                }`,
        variables: {
            empresa_id, codigo_establecimiento, codigo_serie, direccion, departamento, provincia,
            distrito, ubigeo, correo, descripcion, telefono, codigo_sunat, enviar_xml, enviar_email, formato_pdf,
            diseno_pdf, width_ticket, nombre_corto
        }
    }),
    getAll: (client, empresa_id, recursos) => client.query({
        query: gql`query getLocales($empresa_id: String!){
                  locales(empresa_id: $empresa_id) {
                        ${recursos}
                    }
                }`,
        variables: {
            empresa_id
        },
        fetchPolicy: 'no-cache'
    }),
    emitVoucher: (client, empresa_id, recursos) => client.query({
        query: gql`query emitVoucher($empresa_id: String!){
                  emitVoucher(empresa_id: $empresa_id) {
                        ${recursos}
                    }
                }`,
        variables: {
            empresa_id
        },
        fetchPolicy: 'no-cache'
    }),
    getAct: (client, recursos) => client.query({
        query: gql`{
                  localesAct{
                        ${recursos}
                    }
                }`,
        fetchPolicy: 'no-cache'
    }),
    localesByempresa: (client, recursos) => client.query({
        query: gql`{
                  locales{
                        ${recursos}
                    }
                }`,
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query getLocalById($id: String!){
                  localById(id: $id) {
                        ${recursos}
                    }
                }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    update: (client, {
        id, codigo_establecimiento, codigo_serie, direccion, departamento, provincia, distrito, ubigeo,
        correo, descripcion, telefono, codigo_sunat, enviar_xml, enviar_email, formato_pdf, diseno_pdf, width_ticket,
        incluir_guias_kardex, nombre_corto
    }, recursos) => {
        return client.mutate({
            mutation: gql`mutation updateLocal($id: String!, $update: JSON!){
                updateLocal(id: $id, update: $update) {
                    ${recursos}
                  }
                }`,
            variables: {
                id,
                update: {
                    codigo_establecimiento, codigo_serie, direccion, departamento,
                    provincia, distrito, ubigeo, correo, descripcion, telefono, codigo_sunat, enviar_xml, enviar_email,
                    formato_pdf, diseno_pdf, width_ticket, incluir_guias_kardex, nombre_corto
                }
            }
        })
    },
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteLocal($id: String!){
                deleteLocal(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id
        }
    })
}
export default LocalModel