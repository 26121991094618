import React, {Fragment, useEffect, useState} from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {connect} from 'react-redux'
import cx from 'classnames'
import {Col, Row} from 'reactstrap'
import moment from 'moment'
import 'moment/locale/es'

import Notificaciones from './Components/Notificaciones'
import UserBox from './Components/UserBox'
import {decodeToken} from '../../utils/scripts'
import {useAuth} from '../../Context'
import HeaderLogo from '../AppLogo'
import {Empresa} from "../../Components/Models"

const Header = ({headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow}) => {
    const {client, auth, company, setCompany} = useAuth()

    const [view, setView] = useState(<></>)

    const viewEmpresa = company => {
        let modulo_id = auth.modulo_id ? auth.modulo_id : ''
        const local = company.local ? company.local : {}
        let empresaLocal = modulo_id === '24414f8c-1a66-11ec-9149-dfe7ef2f825e' && local.direccion ? local.direccion : company.razon_social
        let nombre_corto_local = local && local.nombre_corto && local.nombre_corto !== '' ? `(${local.nombre_corto})` : ''
        return (<Fragment>
            {company && company.ruc ?
                <Row className="text-center mr-0">
                    <Col sm="12" md="12" xl="12">
                        <b>{company.ruc} - {empresaLocal.toString().toUpperCase()} - {moment(company.periodo).locale("es").format("MMMM YYYY").toUpperCase()} {nombre_corto_local}</b>
                    </Col>
                </Row> : <></>
            }
        </Fragment>)
    }

    useEffect(() => {
        let {email, id, sub, role} = decodeToken(auth.authentication),
            Tawk_API = Tawk_API || {},
            Tawk_LoadStart = new Date()

        Tawk_API.embedded = `tawk_${process.env.REACT_APP_PROPERTYID}`;

        (() => {
            let s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0]
            s1.async = true
            s1.src = `https://embed.tawk.to/${process.env.REACT_APP_PROPERTYID}/${process.env.REACT_APP_TAWKID}`
            s1.charset = 'UTF-8'
            s1.setAttribute('crossorigin', '*')
            s0.parentNode.insertBefore(s1, s0)
        })();

        window.Tawk_API = window.Tawk_API || {}

        window.Tawk_API.visitor = {
            id: id,
            name: sub,
            enterprise: company.razon_social,
            rucEmpresa: company.ruc,
            email: email,
            role: role
        }
        window.Tawk_API.onLoad = function () {
            window.Tawk_API.addTags([`Rol del Usuario : ${role}`, `Razon Social : ${company.razon_social}`, `Ruc : ${company.ruc}`], function (error) {
            });
        }

        window.Tawk_API.onLoad = function () {
            window.Tawk_API.setAttributes({
                'idUsuario': id,
                'rolUsuario': role,
                'razonEmpresa': company.razon_social,
                'rucEmpresa': company.ruc,
            }, function (error) {
            })
        }

        window.Tawk_API.onAgentLeaveChat = function () {
            window.Tawk_API.endChat()
        }
    }, [])

    useEffect(() => {
        if (!company.id) return
        setView(viewEmpresa(company))
    }, [company])

    useEffect(() => {
        if (!auth.authentication) return
        const {id} = decodeToken(auth.authentication)
        const subscribe = Empresa
            .newSelectCompany(client, id, `selectCompany`)
            .subscribe({
                next(response) {
                    const {selectCompany} = response.data.newSelectCompany
                    setCompany(selectCompany)
                },
                error(error) {
                    console.log(error)
                },
                complete(complete) {
                    console.log(complete)
                }
            })
        return () => subscribe.unsubscribe()
    }, [auth.authentication])

    return (
        <Fragment>
            <ReactCSSTransitionGroup component='div'
                                     className={cx('app-header', headerBackgroundColor, {'header-shadow': enableHeaderShadow})}
                                     transitionName='HeaderAnimation' transitionAppear={true}
                                     transitionAppearTimeout={1500}
                                     transitionEnter={false} transitionLeave={false}>
                <HeaderLogo/>

                <div className={cx('app-header__content', {'header-mobile-open': enableMobileMenuSmall})}>
                    <div className='app-header-right'>
                        {view}
                        <Notificaciones/>
                        <UserBox/>
                    </div>
                </div>
            </ReactCSSTransitionGroup>
        </Fragment>
    )
}

const mapStateToProps = ({ThemeOptions}) => ({
    enableHeaderShadow: ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: ThemeOptions.enableMobileMenuSmall
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
