import gql from "graphql-tag";

const DetalleCentroCosto ={

    getById: (client, id, recursos) => client.query({
        query: gql`query detalleAmarreCentroCosto($id: String!) {
                detalleAmarreCentroCosto(id: $id) {
                      ${recursos}
                  }
              }`,
        variables: {
            id
        },
        fetchPolicy: 'no-cache'
    }),
}
export  default DetalleCentroCosto
