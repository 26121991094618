import gql from 'graphql-tag'

const AmarreCentroCosto = {
    create: (client, {codigo, nombre, detalle}, recursos) => client.mutate({
        mutation: gql`mutation createAmarreCentroCosto(
        $codigo: String!, $nombre: String!, $detalle: JSON!) {
        createAmarreCentroCosto(codigo: $codigo, nombre: $nombre, detalle: $detalle){
                ${recursos}
            }
        }`,
        variables: {
            codigo, nombre, detalle
        }
    }),
    update: (client, {amarre_id, codigo, nombre, detalle}, recursos) => client.mutate({
        mutation: gql`mutation updateAmarreCentroCosto($amarre_id: String!, $codigo: String!, $nombre: String!, $detalle: JSON!) {
                updateAmarreCentroCosto(amarre_id: $amarre_id, codigo: $codigo, nombre: $nombre, detalle: $detalle) {
                    ${recursos}
                  }
                }`,
        variables: {
            amarre_id, codigo, nombre, detalle
        }
    }),
    getAll: (client, withEliminated, recursos) => client.query({
        query: gql`query amarreCentroCostos($withEliminated: Boolean!) {
            amarreCentroCostos(withEliminated: $withEliminated) {
                ${recursos}
            }
        }`,
        variables: {
            withEliminated
        },
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query amarreCentroCosto($id: String!) {
                amarreCentroCosto(id: $id) {
                      ${recursos}
                  }
              }`,
        variables: {
            id
        },
        fetchPolicy: 'no-cache'
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteAmarreCentroCosto($id: String!){
                  deleteAmarreCentroCosto(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id
        }
    }),
    activar: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activarAmarreCentroCosto($id: String!){
                  activarAmarreCentroCosto(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id
        }
    }),
    getByCodigo: (client, codigo, recursos) => client.query({
        query: gql`query  amarreCentroCostosByCodigo ($codigo: String!){
        
        amarreCentroCostosByCodigo(codigo: $codigo) 
        {${recursos}}
        }`,
        variables: {
            codigo
        },
        fetchPolicy: 'no-cache'
    }),
    getByParam: (client, param, recursos) => client.query({
        query: gql`query amarreCcByParam($param: String!){
                amarreCcByParam(param: $param) {
                      ${recursos}
                  }
              }`,
        variables: {
            param
        },
        fetchPolicy: 'no-cache'
    }),
}

export default AmarreCentroCosto