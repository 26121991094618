import React, {useEffect, useState} from "react"
import moment from "moment"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChartLine} from "@fortawesome/free-solid-svg-icons"
import Chart from "react-apexcharts"
import {Col, Row} from "reactstrap"

import {useAuth} from "../../../Context"
import {Reportes} from "../../../Components/Models"

const RankingProductosProveedoresClientes = () => {
    const {client, toast, company} = useAuth()

    const [mcProducto, setMcProducto] = useState({
        options: {
            chart: {height: 350, type: 'area', toolbar: {show: true}},
            stroke: {width: [4, 4]},
            labels: [],
            xaxis: {type: 'month'},
            yaxis: [{title: {text: 'Importes'}}]
        },
        series: []
    })
    const [mcCliente, setMcCliente] = useState({
        options: {
            chart: {height: 350, type: 'area', toolbar: {show: true}},
            stroke: {width: [4, 4]},
            labels: [],
            xaxis: {type: 'month'},
            yaxis: [{title: {text: 'Importes'}}]
        },
        series: []
    })
    const [mcProveedor, setMcProveedor] = useState({
        options: {
            chart: {height: 350, type: 'area', toolbar: {show: true}},
            stroke: {width: [4, 4]},
            labels: [],
            xaxis: {type: 'month'},
            yaxis: [{title: {text: 'Importes'}}]
        },
        series: []
    })

    useEffect(() => {
        const fecha_Inicio = moment(company.periodo, 'YYYY-MM').startOf('month').format('YYYY-MM-DD')
        const fecha_Fin = moment(company.periodo, 'YYYY-MM').endOf('month').format('YYYY-MM-DD')
        Reportes
            .rankingProductos(client, {fecha_Inicio, fecha_Fin, downloadExcel: false, tipo: 'productos'}, 'data')
            .then(response => {
                const {data} = response.data.rankingProductos
                setMcProducto(prevState => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        labels: data.map(({codigopro, descripcion}) => `${codigopro}-${descripcion.substring(0, 15)}...`),
                        dataLabels: {
                            enabled: true,
                            enabledOnSeries: [1]
                        },
                        yaxis: [{
                            title: {
                                text: 'Cantidades',
                            },

                        }, {
                            opposite: true,
                            title: {
                                text: 'Importes'
                            }
                        }]
                    },
                    series: [
                        {name: 'Cantidad', type: 'column', data: data.map(({cantidad}) => Number(cantidad).toFixed(2))},
                        {name: 'Importe', type: 'line', data: data.map(({total}) => Number(total).toFixed(2))},
                    ]
                }))
            })
            .catch(({message}) => {
                toast.error(message, {autoClose: 3000})
            })
        Reportes
            .rankingProductos(client, {fecha_Inicio, fecha_Fin, downloadExcel: false, tipo: 'clientes'}, 'data')
            .then(response => {
                const {data} = response.data.rankingProductos
                setMcCliente(prevState => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        labels: data.map(({numero, razon_social}) => `${numero}-${razon_social.substring(0, 15)}...`),
                        dataLabels: {
                            enabled: true,
                            enabledOnSeries: [1]
                        },
                        yaxis: [{
                            title: {
                                text: 'N° Ordenes',
                            },

                        }, {
                            opposite: true,
                            title: {
                                text: 'Importes'
                            }
                        }]
                    },
                    series: [
                        {name: 'N° Ordenes', type: 'column', data: data.map(({numeroordenes}) => Number(numeroordenes).toFixed(2))},
                        {name: 'Importe', type: 'line', data: data.map(({total}) => Number(total).toFixed(2))},
                    ]
                }))
            })
            .catch(({message}) => {
                toast.error(message, {autoClose: 3000})
            })
        Reportes
            .rankingProductos(client, {fecha_Inicio, fecha_Fin, downloadExcel: false, tipo: 'proveedores'}, 'data')
            .then(response => {
                const {data} = response.data.rankingProductos
                setMcProveedor(prevState => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        labels: data.map(({numero, razon_social}) => `${numero}-${razon_social.substring(0, 15)}...`),
                        dataLabels: {
                            enabled: true,
                            enabledOnSeries: [1]
                        },
                        yaxis: [{
                            title: {
                                text: 'N° Ordenes',
                            },

                        }, {
                            opposite: true,
                            title: {
                                text: 'Importes'
                            }
                        }]
                    },
                    series: [
                        {name: 'N° Ordenes', type: 'column', data: data.map(({numeroordenes}) => Number(numeroordenes).toFixed(2))},
                        {name: 'Importe', type: 'line', data: data.map(({total}) => Number(total).toFixed(2))},
                    ]
                }))
            })
            .catch(({message}) => {
                toast.error(message, {autoClose: 3000})
            })
    }, [])

    return (
        <Row>
            <Col md={12}>
                <span style={{fontWeight: 'bold'}}>
                    <FontAwesomeIcon icon={faChartLine} className='text-warning mr-2'/>RANKING DE PRODUCTOS
                </span>
                <div className="bar">
                    <Chart options={mcProducto.options} series={mcProducto.series} type="line" width="100%"
                           height="500px"/>
                </div>
            </Col>
            <Col md={12}>
                <span style={{fontWeight: 'bold'}}>
                    <FontAwesomeIcon icon={faChartLine} className='text-warning mr-2'/>RANKING DE CLIENTES
                </span>
                <div className="bar">
                    <Chart options={mcCliente.options} series={mcCliente.series} type="line" width="100%"
                           height="500px"/>
                </div>
            </Col>
            <Col md={12}>
                <span style={{fontWeight: 'bold'}}>
                    <FontAwesomeIcon icon={faChartLine} className='text-warning mr-2'/>RANKING DE PROVEEDORES
                </span>
                <div className="bar">
                    <Chart options={mcProveedor.options} series={mcProveedor.series} type="line" width="100%"
                           height="500px"/>
                </div>
            </Col>
        </Row>
    )
}

export default RankingProductosProveedoresClientes