import gql from 'graphql-tag'

const DetalleCaja = {
    create: (client, {caja_id, saldo_inicial}, recursos) => client.mutate({
        mutation: gql`mutation createDetalleCaja($caja_id: String!, $saldo_inicial : String!) {
            createDetalleCaja(caja_id: $caja_id, saldo_inicial: $saldo_inicial) {
                ${recursos}
            }
        }`,
        variables: {caja_id, saldo_inicial}
    }),
    update: (client, {id, opened_at, closed_at}, recursos) => client.mutate({
        mutation: gql`mutation updateDetalleCaja($id: String!, $update: JSON!){
                  updateDetalleCaja(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id,
            update: {opened_at, closed_at}
        }
    }),
    getDetalleVendedor: (client, {user_id, fecha_Inicio, fecha_Fin, isVendedor}, recursos) => client.query({
        query: gql`query detalleVendedor($user_id: String!, $fecha_Inicio: String, $fecha_Fin: String, $isVendedor: Boolean){
                detalleVendedor(user_id: $user_id, fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin, isVendedor: $isVendedor) {
                      ${recursos}
                  }
              }`,
        variables: {user_id, fecha_Inicio, fecha_Fin, isVendedor},
        fetchPolicy: 'no-cache'
    }),
}

export default DetalleCaja