import gql from "graphql-tag";

const Modulo = {
    create: (client, {codigo, descripcion, detalle}, recursos) => client.mutate({
        mutation: gql`mutation 
       createModulo(
            $codigo: String,
            $descripcion: String,
            $detalle: String,
            ){        
        createModulo(
            codigo: $codigo, 
            descripcion: $descripcion, 
            detalle: $detalle,
            ){
                ${recursos}}}`,
        variables: {
            codigo,
            descripcion,
        },
    }),
    update: (client, {id, codigo, descripcion, detalle}, recursos) => client.mutate({
        mutation: gql`mutation 
    updateModulo(
        $id: String!,
        $update: JSON!){
    updateModulo(
        id: $id, 
        update: $update){
        ${recursos}
        }
        }`,
        variables: {
            id,
            update: {
                codigo,
                descripcion,
                detalle
            },
        },
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation 
        deleteModulo($id: String!) {
            deleteModulo(id: $id) {
                ${recursos}
            }
        }`,
        variables: {id},
        fetchPolicy: "no-cache",
    }),
    activar: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activarRol($id: String!){
                  activarModulo(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id
        }
    }),
    getAll: (client, recursos) => client.query({
        query: gql`query modulos {
              modulos {
                ${recursos}
              }
            }`,
        fetchPolicy: "no-cache"
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query getModulo($id: String!){
                modulo(id: $id) {
                    ${recursos}
                }
            }`,
        variables: {
            id: (id)
        },
        fetchPolicy: "no-cache"
    })
}
export default Modulo
