import gql from 'graphql-tag'


const PlameModel = {
    createUpdate: (client, {id_empleado, code, items}, recursos) => client.mutate({
        mutation: gql`mutation createUpdatePlame(
           $id_empleado: String, $code: String, $items: JSON
        ){
        createUpdatePlame(id_empleado:$id_empleado, code: $code, items:$items){
            ${recursos}
            }
        }`,
        variables: {
            id_empleado, code, items
        },
        fetchPolicy: 'no-cache'
    }),
    deleteByEstructura: (client, {code_estructura}, recursos) => client.mutate({
        mutation: gql`mutation DeletePlameByEstructura($code_estructura: String){
                    DeletePlameByEstructura(code_estructura:$code_estructura){
                    ${recursos}
                    }
        }`,
        variables: {
            code_estructura
        },
        fetchPolicy: 'no-cache'
    }),
    deleteByEmpleado: (client, {code_estructura, id_empleado}, recursos) => client.mutate({
        mutation: gql`mutation deletePlameByEmpleado($code_estructura: String, $id_empleado: String){
                    deletePlameByEmpleado(code_estructura:$code_estructura, id_empleado: $id_empleado){
                    ${recursos}
                    }
        }`,
        variables: {
            code_estructura, id_empleado
        },
        fetchPolicy: 'no-cache'
    })
}

export default PlameModel