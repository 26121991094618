import gql from 'graphql-tag'
import {isToken} from "../../utils/scripts";

const ReclamationModel = {
    createRelamation: (client, {
        dates, namefull, ubicate, address, email, phone, description, nro, opcion0, opcion1, option2, firma, archivos, pdf
    }, recursos) => client.mutate({
        mutation: gql`mutation createRelamation(
                     $dates:       String, 
                    $namefull:    String, 
                    $ubicate:     String, 
                    $address:     String, 
                    $email:       String, 
                    $phone:       String, 
                    $description: String, 
                    $nro:         String, 
                    $opcion0:     String, 
                    $opcion1:     String, 
                    $option2:     String, 
                    $firma:       String,
                    $archivos:    String,
                    $pdf:         String,
                 ) {
                  createRelamation(
                        dates:       $dates, 
                        namefull:    $namefull, 
                        ubicate:     $ubicate, 
                        address:     $address, 
                        email:       $email, 
                        phone:       $phone, 
                        description: $description, 
                        nro:         $nro, 
                        opcion0:     $opcion0, 
                        opcion1:     $opcion1, 
                        option2:     $option2, 
                        firma:       $firma,
                        archivos:    $archivos,
                        pdf:         $pdf,
                    ) {
                    ${recursos}
                  }
                }`,
        variables: {
            dates, namefull, ubicate, address, email, phone, description, nro, opcion0, opcion1, option2, firma, archivos, pdf
        }
    }),
    createPdfReclamation: (client, {
        dates, namefull, ubicate, address, email, phone, description, nro, opcion0, opcion1, option2, firma, archivos
    }, recursos) => client.mutate({
        mutation: gql`mutation createPdfReclamation(
                    $dates:       String, 
                    $namefull:    String, 
                    $ubicate:     String, 
                    $address:     String, 
                    $email:       String, 
                    $phone:       String, 
                    $description: String, 
                    $nro:         String, 
                    $opcion0:     String, 
                    $opcion1:     String, 
                    $option2:     String, 
                    $firma:       String,
                    $archivos:    String,
                 ) {
                  createPdfReclamation(
                        dates:       $dates, 
                        namefull:    $namefull, 
                        ubicate:     $ubicate, 
                        address:     $address, 
                        email:       $email, 
                        phone:       $phone, 
                        description: $description, 
                        nro:         $nro, 
                        opcion0:     $opcion0, 
                        opcion1:     $opcion1, 
                        option2:     $option2, 
                        firma:       $firma,
                        archivos:    $archivos,
                    ) {
                    ${recursos}
                  }
                }`,
        variables: {
            dates, namefull, ubicate, address, email, phone, description, nro, opcion0, opcion1, option2, firma, archivos
        }
    }),
    searchReclamation: (client, { nro, phone }, recursos) => client.mutate({
        mutation: gql`mutation searchReclamation(
            $nro:   String,
            $phone: String,
        ) {
            searchReclamation(
                nro:   $nro,
                phone: $phone,
            ) {
                ${recursos}
            }
        }`,
        variables: {
            nro, phone,
        }
    }),
    updateReclamation: (client, { id, description, created, idReclamation, status }, recursos) => client.mutate({
        mutation: gql`mutation updateReclamation(
            $id:            String,
            $description:   String,
            $created:       Boolean,
            $idReclamation: String,
            $status:        String,
        ) {
            updateReclamation(
                id:             $id,
                description:    $description,
                created:        $created,
                idReclamation:  $idReclamation,
                status:         $status,
            ) {
                ${recursos}
            }
        }`,
        variables: {
            id, description, created, idReclamation, status
        }
    }),
    filterReclamation: (client, nro, phone, recursos) => client.query({
        query: gql`query filterReclamation(
            $nro: String,
            $phone: String,
        ) {
            filterReclamation(
                nro: $nro,
                phone: $phone,
            ) {
                ${recursos}
            }
        }`,
        variables: {
            nro, phone
        }
    }),
    selectReclamation: (client, recursos) => client.query({
        query: gql`query selectReclamation {
            selectReclamation {
                ${recursos}
            }
        }`,
        fetchPolicy: "no-cache"
    }),
}
export default ReclamationModel