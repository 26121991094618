import gql from 'graphql-tag'

const UbigeoModel = {
    getByParam: (client, param, recursos) => client.query({
        query: gql`query ubigeoByParam($param: String!){
                ubigeoByParam(param: $param) {
                      ${recursos}
                  }
              }`,
        variables: {
            param
        },
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query ubigeoById($id: String){
                ubigeoById(id: $id) {
                      ${recursos}
                  }
              }`,
        variables: {
            id
        },
        fetchPolicy: 'no-cache'
    })
}
export default UbigeoModel