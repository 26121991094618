import gql from 'graphql-tag'

const CapitulosModel = {
    create: (client, {codigo, nombre, descripcion, curso_id, videos}, recursos) => client.mutate({
        mutation: gql`mutation createCapitulos($codigo: String, $nombre: String, $descripcion: String, $curso_id: String, $videos: JSON){
                createCapitulos(codigo: $codigo, nombre: $nombre, descripcion: $descripcion, curso_id: $curso_id, videos:$videos){
                    ${recursos}                
                }
        }`,
        variables: {
            codigo, nombre, descripcion, curso_id, videos
        }, fetchPolicy: 'no-cache'
    }),
    update: (client, {id, codigo, nombre, descripcion, curso_id, videos}, recursos) => client.mutate({
        mutation: gql`mutation updateCapitulos($id: String, $update: JSON, $videos: JSON ){
            updateCapitulos(id: $id, update: $update, videos: $videos){
                ${recursos}
            }    
        }`,
        variables: {
            id, update: {codigo, nombre, descripcion, curso_id}, videos
        }
    }),
    activateDelete: (client, {id, tipo}, recursos) => client.mutate({
        mutation: gql`mutation deleteActivateCapitulo($id: String, $tipo:String){
                deleteActivateCapitulo(id: $id, tipo: $tipo){
                    ${recursos}                
                }
        }`,
        variables: {id, tipo},
        fetchPolicy: 'no-cache'
    })
}
export default CapitulosModel