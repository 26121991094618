import gql from "graphql-tag"

const User = {
    create: (client, {email, nombres, a_paterno, a_materno, avatar, password, role_id}, recursos) => client.mutate({
        mutation: gql`mutation createUser(
                    $email: String!, 
                    $nombres: String!, 
                    $a_paterno: String, 
                    $a_materno: String, 
                    $avatar: String, 
                    $password: String!, 
                    $role_id: String
                 ) {
                  createUser(
                    email: $email, 
                    nombres: $nombres, 
                    a_paterno: $a_paterno, 
                    a_materno: $a_materno, 
                    avatar: $avatar, 
                    password: $password,
                    role_id: $role_id
                    ) {
                    ${recursos}
                  }
                }`,
        variables: {
            email,
            nombres,
            a_paterno,
            a_materno,
            avatar,
            password,
            role_id: String(role_id)
        }
    }),
    createSub: (client, {id_user,name, first, last, gmail, pass, rol, adm_empresa, adm_local, module, demo}) => client.mutate({
        mutation: gql`mutation createSubAccount(
                $id_user: String, 
                $name: String, 
                $first: String, 
                $last: String, 
                $gmail: String, 
                $pass: String, 
                $rol: String,
                $adm_empresa: JSON,
                $adm_local: JSON,
                $module: JSON,
                $demo: Boolean,
             ) {
        createSubAccount(
            id_user: $id_user, 
            name:   $name, 
            first:  $first, 
            last:   $last, 
            gmail:  $gmail, 
            pass:   $pass,
            rol:    $rol,
            adm_empresa: $adm_empresa,
            adm_local: $adm_local,
            module: $module,
            demo: $demo,
            ) {
                id
            }
        }`,
        variables: {
            id_user, name, first, last, gmail, pass, rol, adm_empresa ,adm_local, module, demo
        }
    }),
    deleteSub: (client, id) => client.mutate({
        mutation: gql`mutation deleteSubAccount(
                $id: String,
             ) {
        deleteSubAccount(
            id: $id,
            ) {
            id
          }
        }`,
        variables: {
            id
        }
    }),
    getAll: (client, recursos) => client.query({
        query: gql`{
              users {
                ${recursos}
              }
            }`,
        fetchPolicy: "no-cache"
    }),

    getById: (client, id, recursos) => client.query({
        query: gql`query getUser($id: String!){
                user(id: $id) {
                    ${recursos}
                }
            }`,
        variables: {
            id: String(id)
        },
        fetchPolicy: "no-cache"
    }),
    subUser: (client, id) => client.query({
        query: gql`query subUser($user_id: String!){
            subUser(user_id: $user_id) {
                id
            }
        }`,
        variables: {
            user_id: id
        },
        fetchPolicy: "no-cache"
    }),
    getActive: (client, recursos) => client.query({
        query: gql`{
              userActive {
                ${recursos}
              }
            }`,
        fetchPolicy: "no-cache"
    }),
    update: (client, {
        id, email, nombres, a_paterno, a_materno, avatar, password, role_id, accesos
    }, recursos) => client.mutate({
        mutation: gql`mutation updateUser($id: String!, $update: JSON!){
                updateUser(id: $id, update: $update) {
                    ${recursos}
                  }
                }`,
        variables: {
            id: String(id),
            update: {
                email,
                nombres,
                a_paterno,
                a_materno,
                avatar,
                password,
                role_id,
                accesos
            }
        }
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteUser($id: String!){
                deleteUser(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id: String(id),
        }
    }),
    activate: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activateUser($id: String!){
        activateUser(id: $id){
        ${recursos}
        }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    getByRol: (client, rol, recuros) => client.query({
        query: gql`query userbyRol($rol: String!){
                  userbyRol(rol: $rol){
                  ${recuros}
                  }
        }`,
        variables: {
            rol: String(rol)
        },
        fetchPolicy: 'no-cache'
    }),
    createWithRol: (client, {
        email, nombres, a_paterno, a_materno, avatar, password, role_id, telefono, accesos, numero
    }, recursos) => client.mutate({
        mutation: gql`mutation createUserRole(
                    $email: String!, 
                    $nombres: String!, 
                    $a_paterno: String, 
                    $a_materno: String, 
                    $avatar: String, 
                    $password: String!, 
                    $role_id: String,
                    $telefono: String,
                    $accesos: JSON,
                    $numero: String
                 ) {
                  createUserRole(
                    email: $email, 
                    nombres: $nombres, 
                    a_paterno: $a_paterno, 
                    a_materno: $a_materno, 
                    avatar: $avatar, 
                    password: $password,
                    role_id: $role_id,
                    telefono: $telefono,
                    accesos: $accesos,
                    numero: $numero
                    ) {
                    ${recursos}
                  }
                }`,
        variables: {
            email,
            nombres,
            a_paterno,
            a_materno,
            avatar,
            password,
            role_id: String(role_id),
            telefono,
            accesos,
            numero
        },
        fetchPolicy: 'no-cache'
    }),
    getByLocal: (client, recursos) => client.query({
        query: gql`{
              usersByLocal {
                ${recursos}
              }
            }`,
        fetchPolicy: "no-cache"
    }),
}

export default User