import gql from "graphql-tag"

const Permission = {
    getAll: (client, recursos) => {
        return client.query({
            query: gql`{
              permissions {
                ${recursos}
              }
            }`,
            fetchPolicy: "no-cache"
        })
    },

    getPermission: (client, {module}) => {
        return client.query({
            query: gql`query getPermission (
                $module: String
            ) {
                getPermission (
                    module: $module
                ){
                    permits
                }
            }
            `,
            variables: {
                module
            },
            fetchPolicy: "no-cache",
        })
    },
}

export default Permission