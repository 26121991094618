import gql from 'graphql-tag'

const ReportesModel = {
    reporteVentas: (client, periodo, recursos) => client.query({
        query: gql`query reporteVentas($periodo: String){
            reporteVentas(periodo: $periodo){
                ${recursos}
            }
        }`,
        variables: {
            periodo
        },
        fetchPolicy: 'no-cache'
    }),
    downloadExcel: (client, {periodo, isVenta, excel_pdf}, recursos) => client.query({
        query: gql`query descargarExcel($periodo: String, $isVenta: Boolean, $excel_pdf: String){
            descargarExcel(periodo: $periodo, isVenta: $isVenta, excel_pdf: $excel_pdf){
                ${recursos}
            }
        }`,
        context: {timeout: 480000},
        variables: {periodo, isVenta, excel_pdf},
        fetchPolicy: 'no-cache'
    }),
    reporteCompras: (client, periodo, recursos) => client.query({
        query: gql`query reporteCompras($periodo: String){
            reporteCompras(periodo: $periodo){
                ${recursos}
            }
        }`,
        variables: {
            periodo
        },
        fetchPolicy: 'no-cache'
    }),
    getConstancia: (client, {periodo, type}, recursos) => client.query({
        query: gql`query constanciaLeCvd($periodo: String!, $type: String!){
            constanciaLeCvd(periodo: $periodo, type: $type){
                ${recursos}
            }
        }`,
        variables: {
            periodo, type
        },
        fetchPolicy: 'no-cache'
    }),
    LEVentas: (client, periodo, recursos) => client.query({
        query: gql`query LEVentas($periodo: String){
            LEVentas(periodo: $periodo){
                ${recursos}
            }
        }`,
        variables: {
            periodo
        },
        context: {timeout: 240000},
        fetchPolicy: 'no-cache'
    }),
    descargarTXT: (client, {periodo, type}, recursos) => client.query({
        query: gql`query txtDescargar($periodo: String!, $type: String!){
            txtDescargar(periodo: $periodo, type: $type){
                ${recursos}
            }
        }`,
        variables: {
            periodo, type
        },
        fetchPolicy: 'no-cache'
    }),
    LECompras: (client, {periodo}, recursos) => client.query({
        query: gql`query LECompras($periodo: String!){
            LECompras(periodo: $periodo){
                ${recursos}
            }
        }`,
        variables: {
            periodo
        },
        fetchPolicy: 'no-cache'
    }),
    Kardex13: (client, {periodo, isCosto, conNotas}, recursos) => client.query({
        query: gql`query kardex13($periodo: String , $isCosto: Boolean, $conNotas: Boolean){
            kardex13(periodo: $periodo, isCosto: $isCosto,conNotas: $conNotas){
                ${recursos}
            }
        }`,
        variables: {
            periodo, isCosto, conNotas
        },
        fetchPolicy: 'no-cache'
    }),
    Kardex13vBf: (client, {periodo, isCosto, conNotas, local_id}, recursos) => client.query({
        query: gql`query kardex13vBf($periodo: String, $isCosto: Boolean, $conNotas: Boolean, $local_id: String) {
            kardex13vBf(periodo: $periodo, isCosto: $isCosto, conNotas: $conNotas, local_id: $local_id){
                ${recursos}
            }
        }`,
        variables: {
            periodo, isCosto, conNotas, local_id
        },
        fetchPolicy: 'no-cache'
    }),
    getLeDiario: (client, {periodo, type}, recursos) => client.query({
        query: gql`query getLeDiario($periodo: String!, $type: String!){
                lEdiario(periodo: $periodo, type: $type) {
                    ${recursos}
                }
            }`,
        context: {timeout: 960000},
        variables: {periodo, type},
        fetchPolicy: 'no-cache'
    }),
    getTxtLeCvd: (client, {periodo, type}, recursos) => client.query({
        query: gql`query getTxtLeCvd($periodo: String!, $type: String!) {
              txtLeCvd(periodo: $periodo, type: $type) {
                ${recursos}
              }
            }`,
        // context: {headers: {isAuth: isToken()}},
        variables: {periodo, type},
        fetchPolicy: "no-cache"
    }),
    getConstanciaLeCvd: (client, {periodo, type}, recursos) => client.query({
        query: gql`query getConstanciaLeCvd($periodo: String!, $type: String!) {
              constanciaLeCvd(periodo: $periodo, type: $type) {
                ${recursos}
              }
            }`,
        // context: {headers: {isAuth: isToken()}},
        variables: {periodo, type},
        fetchPolicy: "no-cache"
    }),
    excelPCGE: (client, recursos) => client.query({
        query: gql`{
            excelPCGE{
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    excelCuentasPorCobrar: (client, {criterio, param, libro}, recursos) => client.query({
        query: gql`query excelCuentasPorCobrar($criterio: String!, $param: String!, $libro: String!){
                excelCuentasPorCobrar(criterio: $criterio, param: $param, libro: $libro){
                    ${recursos}
                }
            }`,
        variables: {criterio, param, libro},
        fetchPolicy: "no-cache"
    }),
    excelVentasCompras: (client, {libro, fecha_desde, fecha_hasta, isBody}, recursos) => client.query({
        query: gql`query excelVentasCompras($libro: String!, $fecha_desde: String, $fecha_hasta: String, $isBody: Boolean){
                excelVentasCompras(libro: $libro, fecha_desde: $fecha_desde, fecha_hasta: $fecha_hasta, isBody: $isBody) {
                      ${recursos}
                  }
              }`,
        context: {timeout: 480000},
        variables: {libro, fecha_desde, fecha_hasta, isBody},
        fetchPolicy: 'no-cache'
    }),
    excelCuentasFinancieras: (client, recursos) => client.query({
        query: gql`{
              excelCuentasFinancieras {
                ${recursos}
              }
            }`,
        fetchPolicy: 'no-cache'
    }),
    excelKardex13: (client, {isUnidades, periodo, conNotas, excelPdf}, recursos) => client.query({
        query: gql`query excelkardex13($periodo: String, $isUnidades: Boolean, $conNotas: Boolean, $excelPdf: String){
            excelkardex13(periodo: $periodo, isUnidades: $isUnidades, conNotas: $conNotas, excelPdf: $excelPdf){
                ${recursos}
            }
        }`,
        context: {timeout: 480000},
        variables: {periodo, isUnidades, conNotas, excelPdf},
        fetchPolicy: 'no-cache'
    }),
    excelStock: (client, {periodo, conNotas, downloadPdf}, recursos) => client.query({
        query: gql`query excelStock($periodo: String, $conNotas: Boolean , $downloadPdf: Boolean ){
            excelStock(periodo: $periodo, conNotas: $conNotas, downloadPdf: $downloadPdf){
                ${recursos}
            }
        }`,
        context: {timeout: 480000},
        variables: {
            periodo, conNotas, downloadPdf
        },
        fetchPolicy: 'no-cache'
    }),
    excelStockvBf: (client, {periodo, conNotas, downloadPdf, local_id}, recursos) => client.query({
        query: gql`query excelStockvBf($periodo: String, $conNotas: Boolean , $downloadPdf: Boolean, $local_id: String){
            excelStockvBf(periodo: $periodo, conNotas: $conNotas, downloadPdf: $downloadPdf, local_id: $local_id){
                ${recursos}
            }
        }`,
        context: {timeout: 480000},
        variables: {
            periodo, conNotas, downloadPdf, local_id
        },
        fetchPolicy: 'no-cache'
    }),
    excelCostos: (client, {periodo, isMargenUtilidad, conNotas, isCosto, downloadPdf}, recursos) => client.query({
        query: gql`query excelCostos($periodo: String, $isMargenUtilidad: Boolean, $conNotas: Boolean, $isCosto: Boolean, $downloadPdf: Boolean){
            excelCostos(periodo: $periodo, isMargenUtilidad: $isMargenUtilidad, conNotas: $conNotas, isCosto: $isCosto, downloadPdf: $downloadPdf){
                ${recursos}
            }
        }`,
        context: {timeout: 480000},
        variables: {
            periodo, isMargenUtilidad, conNotas, isCosto, downloadPdf
        },
        fetchPolicy: 'no-cache'
    }),
    reporteVentasDetallado: (client, periodo, recursos) => client.query({
        query: gql`query reporteVentasDetallado($periodo: String){
            reporteVentasDetallado(periodo: $periodo){
                ${recursos}
            }
        }`,
        variables: {
            periodo
        },
        fetchPolicy: 'no-cache'
    }),
    excelventadetallado: (client, {periodo, downloadPdf, downloadExcel}, recursos) => client.query({
        query: gql`query excelventadetallado($periodo: String, $downloadPdf: Boolean, $downloadExcel: Boolean){
            excelventadetallado(periodo: $periodo, downloadPdf: $downloadPdf, downloadExcel: $downloadExcel){
                ${recursos}
            }
        }`,
        context: {timeout: 480000},
        variables: {
            periodo, downloadPdf, downloadExcel
        },
        fetchPolicy: 'no-cache'
    }),
    getLfHojaTrabajo: (client, {periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLFhojaTrabajo($periodo: String!, $consolidado: Boolean!, $incluirCierre: Boolean!){
                lFhojaTrabajo(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                    ${recursos}
                }
            }`,
        // context: {headers: {isAuth: isToken()}},
        variables: {periodo, consolidado, incluirCierre},
        fetchPolicy: 'no-cache'
    }),
    getExcelLfCvd: (client, {periodo, libro, consolidado, incluirCierre, excel_pdf}, recursos) => client.query({
        query: gql`query getExcelLfCvd($periodo: String!, $libro: String!, $consolidado: Boolean, $incluirCierre: Boolean, $excel_pdf: String) {
              excelLfCvd(periodo: $periodo, libro: $libro, consolidado: $consolidado, incluirCierre: $incluirCierre, excel_pdf: $excel_pdf) {
                ${recursos}
              }
            }`,
        context: {timeout: 480000},
        variables: {periodo, libro, consolidado, incluirCierre, excel_pdf},
        fetchPolicy: "no-cache"
    }),
    getLfMayorDetalle: (client, {cuenta, periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLfMayorDetalle($cuenta: String!, $periodo: String!, $consolidado: Boolean, $incluirCierre: Boolean) {
              lFmayorDetalle(cuenta: $cuenta, periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                ${recursos}
              }
            }`,
        // context: {headers: {isAuth: isToken()}},
        variables: {cuenta, periodo, consolidado, incluirCierre},
        fetchPolicy: "no-cache"
    }),
    getLfCuo: (client, {cuo, periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLfCcuo($cuo: String!, $periodo: String!, $consolidado: Boolean, $incluirCierre: Boolean) {
              lFcuo(cuo: $cuo, periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                ${recursos}
              }
            }`,
        variables: {cuo, periodo, consolidado, incluirCierre},
        fetchPolicy: "no-cache"
    }),
    getLfBgc: (client, {periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLFbgc($periodo: String!, $consolidado: Boolean!, $incluirCierre: Boolean!) {
                lFbgc(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                    ${recursos}
                }
            }`,
        variables: {periodo, consolidado, incluirCierre},
        fetchPolicy: 'no-cache'
    }),
    getLfErn: (client, {periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLErn($periodo: String!, $consolidado: Boolean!, $incluirCierre: Boolean!){
                lFern(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                    ${recursos}
                }
            }`,
        variables: {periodo, consolidado, incluirCierre},
        fetchPolicy: 'no-cache'
    }),
    getLfErf: (client, {periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLErf($periodo: String!, $consolidado: Boolean!, $incluirCierre: Boolean!){
                lFerf(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                    ${recursos}
                }
            }`,
        variables: {periodo, consolidado, incluirCierre},
        fetchPolicy: 'no-cache'
    }),
    getLfLDiario: (client, {periodo}, recursos) => client.query({
        query: gql`query getLFlDiario($periodo: String!){
                lFlDiario(periodo: $periodo) {
                    ${recursos}
                }
            }`,
        // context: {headers: {isAuth: isToken()}},
        variables: {periodo},
        fetchPolicy: 'no-cache'
    }),
    getLfDiarios: (client, {periodo}, recursos) => client.query({
        query: gql`query getLfDiarios($periodo: String!){
                lFdiarios(periodo: $periodo) {
                    ${recursos}
                }
            }`,
        // context: {headers: {isAuth: isToken()}},
        variables: {periodo},
        fetchPolicy: 'no-cache'
    }),
    getLfLMayor: (client, {periodo}, recursos) => client.query({
        query: gql`query getLFmayor($periodo: String!){
                lFmayor(periodo: $periodo) {
                    ${recursos}
                }
            }`,
        // context: {headers: {isAuth: isToken()}},
        variables: {periodo},
        fetchPolicy: 'no-cache'
    }),
    getLfLCaja: (client, {periodo, cuenta_financiera_id, dowExcel, dowPdf}, recursos) => client.query({
        query: gql`query getLFcaja($periodo: String!, $cuenta_financiera_id: String!, $dowExcel: Boolean, $dowPdf: Boolean){
                lFcaja(periodo: $periodo, cuenta_financiera_id: $cuenta_financiera_id, dowExcel: $dowExcel, dowPdf: $dowPdf) {
                    ${recursos}
                }
            }`,
        // context: {headers: {isAuth: isToken()}},
        context: {timeout: 360000},
        variables: {periodo, cuenta_financiera_id, dowExcel, dowPdf},
        fetchPolicy: 'no-cache'
    }),
    getLfLBancos: (client, {periodo, cuenta_financiera_id, dowExcel, dowPdf}, recursos) => client.query({
        query: gql`query getLFBancos($periodo: String!, $cuenta_financiera_id: String!, $dowExcel: Boolean, $dowPdf: Boolean){
                lFbancos(periodo: $periodo, cuenta_financiera_id: $cuenta_financiera_id, dowExcel: $dowExcel, dowPdf: $dowPdf) {
                    ${recursos}
                }
            }`,
        // context: {headers: {isAuth: isToken()}},
        context: {timeout: 360000},
        variables: {periodo, cuenta_financiera_id, dowExcel, dowPdf},
        fetchPolicy: 'no-cache'
    }),
    rankingProductos: (client, {fecha_Inicio, fecha_Fin, downloadExcel, tipo, downloadPdf}, recursos) => client.query({
        query: gql`query rankingProductos($fecha_Inicio: String!, $fecha_Fin: String!, $downloadExcel: Boolean,
        $tipo: String, $downloadPdf: Boolean,){
        rankingProductos(fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin,downloadExcel: $downloadExcel,
        tipo: $tipo, downloadPdf: $downloadPdf){
        ${recursos}
        }
        }`,
        variables: {fecha_Inicio, fecha_Fin, downloadExcel, tipo, downloadPdf},
        fetchPolicy: 'no-cache'
    }),
    excelCajas: (client, {caja_id, fecha_Inicio, fecha_Fin, downloadExcel, isVendedor}, recursos) => client.query({
        query: gql`query reporteCajas($caja_id: String!, $fecha_Inicio: String, $fecha_Fin: String, $downloadExcel: Boolean,
        $isVendedor: Boolean){
        reporteCajas(caja_id: $caja_id, fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin,downloadExcel: $downloadExcel,
        isVendedor: $isVendedor){
        ${recursos}
        }
        }`,
        variables: {caja_id, fecha_Inicio, fecha_Fin, downloadExcel, isVendedor},
        fetchPolicy: 'no-cache'
    }),
    excelVendedor: (client, {user_id, fecha_Inicio, fecha_Fin, isVendedor, downloadExcel}, recursos) => client.query({
        query: gql`query reporteVendedor($user_id: String!, $fecha_Inicio: String, $fecha_Fin: String, $downloadExcel: Boolean,
         $isVendedor: Boolean){
            reporteVendedor(user_id: $user_id, fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin, downloadExcel: $downloadExcel,
             isVendedor: $isVendedor) {
                      ${recursos}
                  }
              }`,
        variables: {user_id, fecha_Inicio, fecha_Fin, isVendedor, downloadExcel},
        fetchPolicy: 'no-cache'
    }),
    excelPedidos: (client, {caja_id, fecha_Inicio, fecha_Fin}, recursos) => client.query({
        query: gql`query excelPedidos($caja_id: String!, $fecha_Inicio: String, $fecha_Fin: String) {
            excelPedidos(caja_id: $caja_id, fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin) {
                  ${recursos}
              }
          }`,
        variables: {caja_id, fecha_Inicio, fecha_Fin},
        fetchPolicy: 'no-cache'
    }),
    getExcelUtilitario: (client, {libro}, recursos) => client.query({
        query: gql`query postExcelUtilitario($libro: String!) {
              excelUtilitario(libro: $libro) {
                ${recursos}
              }
            }`,
        variables: {libro},
        fetchPolicy: "no-cache"
    }),
    excelVoleta: (client, {caja_id, fecha_Inicio, fecha_Fin}, recursos) => client.query({
        query: gql`query excelPedidos($caja_id: String!, $fecha_Inicio: String, $fecha_Fin: String) {
            excelPedidos(caja_id: $caja_id, fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin) {
                  ${recursos}
              }
          }`,
        variables: {caja_id, fecha_Inicio, fecha_Fin},
        fetchPolicy: 'no-cache'
    }),
    getLfEsf: (client, {periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLFesf($periodo: String!, $consolidado: Boolean!, $incluirCierre: Boolean!){
                lFesf(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                    ${recursos}
                }
            }`,
        // context: {headers: {isAuth: isToken()}},
        variables: {periodo, consolidado, incluirCierre},
        fetchPolicy: 'no-cache'
    }),
    getLfEdr: (client, {periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getLFedr($periodo: String!, $consolidado: Boolean!, $incluirCierre: Boolean!){
                lFedr(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                    ${recursos}
                }
            }`,
        variables: {periodo, consolidado, incluirCierre},
        fetchPolicy: 'no-cache'
    }),
    getExcelSmvEdr: (client, {periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getExcelSmvEdr($periodo: String!, $consolidado: Boolean, $incluirCierre: Boolean) {
              excelSmvEdr(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                ${recursos}
              }
            }`,
        variables: {periodo, consolidado, incluirCierre},
        fetchPolicy: "no-cache"
    }),
    getExcelSmvEsf: (client, {periodo, consolidado, incluirCierre}, recursos) => client.query({
        query: gql`query getExcelSmvEsf($periodo: String!, $consolidado: Boolean, $incluirCierre: Boolean) {
              excelSmvEsf(periodo: $periodo, consolidado: $consolidado, incluirCierre: $incluirCierre) {
                ${recursos}
              }
            }`,
        variables: {periodo, consolidado, incluirCierre},
        fetchPolicy: "no-cache"
    }),
    LECompras82: (client, {periodo}, recursos) => client.query({
        query: gql`query LECompras82($periodo: String!){
            LECompras82(periodo: $periodo){
                ${recursos}
            }
        }`,
        variables: {periodo},
        fetchPolicy: 'no-cache'
    }),
    analizadorAsientos: (client, {periodo, query}, recursos) => client.query({
        query: gql`query ${query}($periodo: String!) {
            ${query}(periodo: $periodo) {
                ${recursos}
            }
        }`,
        context: {timeout: 240000},
        variables: {periodo},
        fetchPolicy: 'no-cache'
    }),
    getLeCajaBanco: (client, {periodo, type}, recursos) => client.query({
        query: gql`query getLeCajaBanco($periodo: String!, $type: String!){
                lECajaBanco(periodo: $periodo, type: $type) {
                    ${recursos}
                }
            }`,
        context: {timeout: 960000},
        variables: {periodo, type},
        fetchPolicy: 'no-cache'
    }),
    getLePcge: (client, {periodo, type}, recursos) => client.query({
        query: gql`query getLePcge($periodo: String!, $type: String!){
                lEpcge(periodo: $periodo, type: $type) {
                    ${recursos}
                }
            }`,
        variables: {periodo, type},
        fetchPolicy: 'no-cache'
    }),
    rowLECompras: (client, {periodo, fila}, recursos) => client.query({
        query: gql`query rowLeCompras($periodo: String!, $row: String!){
            rowLeCompras(periodo: $periodo, row: $row){
                ${recursos}
            }
        }`,
        variables: {periodo, row: fila.toString()},
        fetchPolicy: 'no-cache'
    }),
    rowLECompras82: (client, {periodo, fila}, recursos) => client.query({
        query: gql`query rowLeCompras82($periodo: String!, $row: String!){
            rowLeCompras82(periodo: $periodo, row: $row){
                ${recursos}
            }
        }`,
        variables: {periodo, row: fila.toString()},
        fetchPolicy: 'no-cache'
    }),
    rowLEVentas: (client, {periodo, fila}, recursos) => client.query({
        query: gql`query rowLeVentas($periodo: String!, $row: String!){
            rowLeVentas(periodo: $periodo, row: $row){
                ${recursos}
            }
        }`,
        variables: {periodo, row: fila.toString()},
        fetchPolicy: 'no-cache'
    }),
    rowLEDiario: (client, {periodo, fila, type}, recursos) => client.query({
        query: gql`query rowLeDiario($periodo: String!, $row: String!, $type: String!){
            rowLeDiario(periodo: $periodo, row: $row, type: $type){
                ${recursos}
            }
        }`,
        variables: {periodo, row: fila.toString(), type},
        fetchPolicy: 'no-cache'
    }),
    excelPlaniMantenimiento: (client, {empresa_id, excel, id_empleado, periodo, send}, recursos) => client.query({
        query: gql`query excelPlaniMantenimiento($empresa_id: String, $excel: String, $id_empleado : String, 
            $periodo: String, $planilla: String, $employee: String, $banco: String, $periodo1: String, $periodo2: String) {
              excelPlaniMantenimiento(empresa_id: $empresa_id, excel: $excel, id_empleado: $id_empleado, 
              periodo: $periodo, planilla: $planilla, employee: $employee, banco: $banco, periodo1: $periodo1, periodo2: $periodo2,) {
                ${recursos}
              }
            }`,
        variables: {
            empresa_id,
            excel,
            id_empleado,
            periodo,
            planilla: send ? send.planilla : '',
            employee: send ? send.employee : '',
            banco: send ? send.banco : '',
            periodo1: send ? send.periodo1 : '',
            periodo2: send ? send.periodo2 : '',
        },
        fetchPolicy: "no-cache"
    }),
    kardexBF: (client, {type_report, conNotas = false, periodo, id_producto, id_resource, excel}) => client.query({
        query: gql` query kardexBody($type_report: String, $conNotas: Boolean, $periodo: JSON, $id_producto: String, $id_resource: String, $excel: Boolean){
                    kardexBody(type_report: $type_report, conNotas: $conNotas, periodo:$periodo, id_producto: $id_producto, id_resource: $id_resource, excel: $excel){
                        data     
               }    
        }`, variables: {
            type_report, conNotas, periodo, id_producto, id_resource, excel
        },
        fetchPolicy: "no-cache"
    })
}

export default ReportesModel