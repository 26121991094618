import gql from 'graphql-tag'

const ProductoModel = {
    create: (client, {
        codigo, codigo_barras, codigo_sunat, descripcion, detalle, medida_id, categoria_id, inventario_id, tipo_igv,
        valor_unitario, precio_unitario, imagen, icbper, precios, compras_pcge, ventas_pcge, stock,
        valor_etiqueta, precio_etiqueta, valor_mayorista, precio_mayorista, isBodyFitnes = true, modelo_id
    }, recursos) => client.mutate({
        mutation: gql`mutation createProducto(
                        $codigo: String!, 
                        $codigo_barras: String, 
                        $codigo_sunat: String, 
                        $descripcion: String, 
                        $detalle: String, 
                        $medida_id: String, 
                        $categoria_id: String, 
                        $inventario_id: String, 
                        $tipo_igv: String, 
                        $valor_unitario: Float!, 
                        $precio_unitario: Float!, 
                        $imagen: String, 
                        $icbper: Boolean!,
                        $precios: JSON!,
                        $compras_pcge: String,
                        $ventas_pcge: String,
                        $stock: Boolean,
                        $valor_etiqueta: Float, 
                        $precio_etiqueta: Float, 
                        $valor_mayorista: Float, 
                        $precio_mayorista: Float,
                        $isBodyFitnes: Boolean,
                        $modelo_id: String
                     ) {
                        createProducto(                   
                        codigo: $codigo,
                        codigo_barras: $codigo_barras,
                        codigo_sunat: $codigo_sunat,
                        descripcion: $descripcion,
                        detalle: $detalle,
                        medida_id: $medida_id,
                        categoria_id: $categoria_id,
                        inventario_id: $inventario_id,
                        tipo_igv: $tipo_igv,
                        valor_unitario: $valor_unitario,
                        precio_unitario: $precio_unitario,
                        imagen: $imagen,
                        icbper: $icbper,
                        precios: $precios,
                        compras_pcge: $compras_pcge,
                        ventas_pcge: $ventas_pcge,
                        stock: $stock,
                        valor_etiqueta: $valor_etiqueta,
                        precio_etiqueta: $precio_etiqueta,
                        valor_mayorista: $valor_mayorista,
                        precio_mayorista: $precio_mayorista,
                        isBodyFitnes: $isBodyFitnes,
                        modelo_id: $modelo_id
                        ) {
                        ${recursos}
                      }
                    }`,
        variables: {
            codigo, codigo_barras, codigo_sunat, descripcion, detalle, medida_id, categoria_id, inventario_id, tipo_igv,
            valor_unitario: Number(valor_unitario), precio_unitario: Number(precio_unitario), imagen, icbper, precios,
            compras_pcge, ventas_pcge, stock, valor_etiqueta: Number(valor_etiqueta),
            precio_etiqueta: Number(precio_etiqueta), valor_mayorista: Number(valor_mayorista),
            precio_mayorista: Number(precio_mayorista), isBodyFitnes, modelo_id
        }
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query productobyid($id: String!) {
                  productobyid(id: $id) {
                        ${recursos}
                    }
                }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    update: (client, producto, recursos) => client.mutate({
        mutation: gql`mutation updateProducto($id: String!, $update: JSON!, $isBodyFitnes: Boolean) {
                  updateProducto(id: $id, update: $update, isBodyFitnes: $isBodyFitnes) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id: String(producto.id),
            update: {
                ...producto,
                valor_etiqueta: Number(producto.valor_etiqueta), precio_etiqueta: Number(producto.precio_etiqueta),
                valor_mayorista: Number(producto.valor_mayorista), precio_mayorista: Number(producto.precio_mayorista)
            }, isBodyFitnes: true
        }
    }),
    deleted: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deletedProducto($id: String!){
                  deletedProducto(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id: id
        }
    }),
    activar: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activarProducto($id: String!){
                  activarProducto(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id: id
        }
    }),
    getAll: (client, recursos) => client.query({
        query: gql`query productoslist($isBody: Boolean){
        productoslist(isBody: $isBody){
              ${recursos}
          }
      }`,
        variables: {isBody: true},
        fetchPolicy: 'no-cache'
    }),
    getAllByModel: (client, modelo, recursos) => client.query({
        query: gql`query productosByModel($modelo: String){ 
        productosByModel(modelo: $modelo){
              ${recursos}
          }
      }`,
        variables: {modelo},
        fetchPolicy: 'no-cache'
    }),
    getProductsLimit: (client, limite, recursos) => client.query({
        query: gql`query productoslist($limite: JSON, $isBody: Boolean){
        productoslist(limite: $limite, isBody: $isBody){
              ${recursos}
          }
      }`,
        variables: {limite, isBody: true},
        fetchPolicy: 'no-cache'
    }),
    countProductos: (client, recursos) => client.query({
        query: gql`query countProducto($isBody:Boolean){
        countProducto (isBody: $isBody){
            ${recursos}
        }}`,
        variables: {isBody: true},
        context: {timeout: 480000},
        fetchPolicy: 'no-cache'
    }),
    getProductoByCodigo: (client, {codigo, codigo_barras}, recursos) => client.query({
        query: gql`query productoByCodigo($codigo: String!, $codigo_barras: String, $isBody: Boolean){
        productoByCodigo( codigo: $codigo, codigo_barras: $codigo_barras, isBody: $isBody) {
              ${recursos}
          }
      }`,
        variables: {
            codigo: codigo,
            codigo_barras: codigo_barras,
            isBody: true
        },
        fetchPolicy: 'no-cache'
    }),
    getIgvProducto: (client, id, recuros) => client.query({
        query: gql`query igvProducto($id: String!){
                igvProducto(id: $id){
                   ${recuros}
            }
        }
        `,
        variables: {
            id
        },
        fetchPolicy: 'no-cache'
    }),
    searchParam: (client, param, recursos) => client.query({
        query: gql`query productoSearchParam($param: String!, $isBody: Boolean){
                productoSearchParam(param: $param, isBody: $isBody) {
                      ${recursos}
                  }
              }`,
        variables: {param, isBody: true},
        fetchPolicy: 'no-cache'
    }),
    getByParam: (client, param, recursos) => client.query({
        query: gql`query productosbyParam($param: String!, $isBody: Boolean){
                productosbyParam(param: $param, isBody: $isBody) {
                      ${recursos}
                  }
              }`,
        variables: {param, isBody: true},
        fetchPolicy: 'no-cache'
    }),
    getByCategoriaId: (client, categoria_id, recursos) => client.query({
        query: gql`query productsByCategoriaId($categoria_id: String!, $isBody: Boolean){
                productsByCategoriaId(categoria_id: $categoria_id, isBody: $isBody) {
                      ${recursos}
                  }
              }`,
        variables: {categoria_id, isBody: true},
        fetchPolicy: 'no-cache'
    }),
    validarProductos: (client, productos, recursos) => client.query({
        query: gql`query validarProductos($productos: JSON!, $isBodyFitnes: Boolean){
            validarProductos(productos: $productos, isBodyFitnes: $isBodyFitnes){
            ${recursos}
            }
        }`,
        variables: {productos, isBodyFitnes: true},
        context: {timeout: 720000},
        fetchPolicy: 'no-cache'
    }),
    getBycodigo: (client, codigo, recursos) => client.query({
        query: gql`query productoBycodigo($codigo: String, $isBody: Boolean){
        productoBycodigo(codigo:$codigo, isBody: $isBody){
        ${recursos}
        }
        }`,
        variables: {codigo, isBody: true},
        fetchPolicy: 'no-cache'
    }),
    createProductoMasivo: (client, productos, recursos) => client.query({
        query: gql`query createProductosMasivos($productos: JSON!, $isBodyFitnes: Boolean){
                  createProductosMasivos(productos: $productos, isBodyFitnes: $isBodyFitnes) {
                    status
                  }
                }`,
        variables: {productos, isBodyFitnes: true},
        context: {timeout: 1200000},
        fetchPolicy: 'no-cache'

    }),
    getByTop: (client, recursos) => client.query({
        query: gql`query productosTop($isBody: Boolean){
            productosTop(isBody: $isBody){
                  ${recursos}
              }
          }`,
        variables: {isBody: true},
        fetchPolicy: 'no-cache'
    }),
    getCuentaFinanciera: (client, {inventario, medida}, recursos) => client.query({
        query: gql`query cuentasDefaultFinancieras($inventario: String, $medida: String){
        cuentasDefaultFinancieras(inventario:$inventario, medida: $medida){
        ${recursos}
        }
        }`,
        variables: {inventario, medida},
        fetchPolicy: 'no-cache'
    }),
    updateMasivo: (client, {productos}, recursos) => client.mutate({
        mutation: gql`mutation updateProductsCodeFabricacion($productos: JSON!){
                  updateProductsCodeFabricacion(productos: $productos) {
                    ${recursos}
                  }
                }`,
        variables: {productos},
        fetchPolicy: 'no-cache'
    }),
    excelproductoslist: (client, recursos) => client.query({
        query: gql`{
        excelproductoslist{
                  ${recursos}
              }
          }`,
        context: {timeout: 240000},
        fetchPolicy: 'no-cache'
    }),
    stockByProducto: (client, producto_id) => client.query({
        query: gql`query controlStockProducto($producto_id: String!){
                  controlStockProducto(producto_id: $producto_id) {
                        data, controlStock
                    }
                }`,
        variables: {producto_id},
        fetchPolicy: 'no-cache'
    }),
    productoExcludes: (client)=>client.query({
        query: gql`{
              productsExcludeBody {
                data
              }
            }`,
    })
}

export default ProductoModel