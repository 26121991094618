import gql from 'graphql-tag'

const NotificacionModel = {
    getNotificaciones: (client, recursos) => client.query({
        query: gql`{
            ListNotificaciones{
               ${recursos}             
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    update: (client, {id, update}, recursos) => client.mutate({
        mutation: gql`mutation updateNotificacion($id: String!, $update: JSON!){
            updateNotificacion(id: $id, update: $update){
                ${recursos}
            }
        }`,
        variables: {
            id, update
        },
        fetchPolicy: 'no-cache'
    })
}
export default NotificacionModel