import gql from "graphql-tag"

const Gratificacion = {
    create: (client, {insert}) => client.mutate({
        mutation: gql`mutation createGratificacion(
                        $insert: JSON
                     ) {
                        createGratificacion(
                        insert: $insert
                        ) {
                        nombre
                      }
                    }`,
        variables: {
            insert
        }
    }),
    selectAllByPeriodoId: (client, {id_employee, periodo}, recursos) => client.query({
        query: gql`query GratificacionesAllperiodo($id_employee: String!, $periodo: String!){
                  GratificacionesAllperiodo(id_employee: $id_employee, periodo: $periodo) {
                        ${recursos}
                    }
                }`,
        variables: {
            id_employee, periodo
        },
        fetchPolicy: 'no-cache'
    })
}

export default Gratificacion