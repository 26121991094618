import gql from 'graphql-tag'

const Comentarios = {
    Create: (client, {contenido, detalle_capitulo_id, id_comentario, link}, recursos) => client.mutate({
        mutation: gql`mutation createComentarios($contenido: String, $detalle_capitulo_id: String, $id_comentario: String, $link: String){
            createComentarios(contenido: $contenido, detalle_capitulo_id: $detalle_capitulo_id,id_comentario: $id_comentario, link: $link){
                ${recursos}
            }
        }`,
        variables: {contenido, detalle_capitulo_id, id_comentario, link},
        fetchPolicy: "no-cache"
    }),
    Update: (client, {id, contenido}, recursos) => client.mutate({
        mutation: gql`mutation updateComentarios($id: String, $update: JSON){
                   updateComentarios(id: $id, update: $update){
                       ${recursos}       
            }
        }`,
        variables: {id, update: {contenido}},
        fetchPolicy: "no-cache"
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteComenario($id: String){
            deleteComenario(id: $id){
                ${recursos}
            }
        }`,
        variables: {id},
        fetchPolicy: "no-cache"
    }),
    lisentComentario: (client, detalle_capitulo_id, recursos) => client.subscribe({
        query: gql`subscription comentarioVideo($detalle_capitulo_id: String!){
                comentarioVideo(detalle_capitulo_id: $detalle_capitulo_id){
                ${recursos}
             }
        }`, variables: {detalle_capitulo_id}
    })
}
export default Comentarios