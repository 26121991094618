import gql from 'graphql-tag'


const SalarioBeneficioModel = {
    createOrUpdate: (client, {update}, recursos) => client.mutate({
        mutation: gql`mutation createOrUpdateSalarioBeneficio($update: JSON!) {
            createOrUpdateSalarioBeneficio(update: $update) {
                ${recursos}
            }
        }`,
        variables: {update}
    }),
    getById: (client, id, recursos) =>
        client.query({
            query: gql`query categoria($id:String!){
                categoria(id: $id){
                    ${recursos}
                }
            }`,
            variables: {
                id
            },
            fetchPolicy: 'no-cache'
        }),
    getByEmpresaId: (client, recursos) =>
        client.query({
            query: gql`query {
                salariosBeneficiosByEmpresaId {
                    ${recursos}
                }
            }`,
            fetchPolicy: 'no-cache'
        }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteCategoria($id: String!){
            deleteCategoria(id: $id){
                ${recursos}
             }
        }`,
        variables: {id}
    })
}

export default SalarioBeneficioModel