import gql from 'graphql-tag'

const ZonaModel = {
    getAllZonas: (client, recursos) => client.query({
        query: gql`{
        zonas {
            ${recursos}
        }}`,
        fetchPolicy: 'no-cache'
    }),
    create: (client, {codigo, descripcion, control_mesa}, recursos) => client.mutate({
        mutation: gql`mutation createZona($codigo: String, $descripcion: String, $control_mesa: Boolean)
        {createZona(codigo: $codigo, descripcion: $descripcion, control_mesa: $control_mesa){
        ${recursos}
        }
        }`,
        variables: {codigo, descripcion, control_mesa}
    }),
    update: (client, {id, codigo, descripcion, control_mesa, mesas}, recursos) => client.mutate({
        mutation: gql`mutation updateZona($id: String!,$update: JSON!) {
        updateZona(id: $id, update: $update) {
        ${recursos}
        }
        }`,
        variables: {
            id,
            update: {
                codigo, descripcion, control_mesa, mesas
            }
        }
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query getById($id: String!){
                   getById(id: $id){
                   ${recursos}
                   }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    activate: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activarZona($id: String!){
        activarZona(id: $id){
        ${recursos}
        }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteZona($id: String!){
        deleteZona(id: $id){
        ${recursos}
             }
        }`,
        variables: {id},
        fetchPolicy: 'no-cache'
    })
}

export default ZonaModel