import gql from 'graphql-tag'

const ProveedorModel = {
    create: (client, {
        numero, razon_social, direccion, departamento, provincia, distrito, ubigeo, estado, condicion,
        documento_id, observacion, email, telefono
    }, recursos) => client.mutate({
        mutation: gql`mutation createProveedor(
                        $numero: String!,
                        $razon_social: String!,
                        $direccion: String,
                        $departamento: String,
                        $provincia: String,
                        $distrito: String,
                        $ubigeo: String,
                        $estado: String,
                        $condicion: String,
                        $documento_id: String!,
                        $observacion: String,
                        $email: String,
                        $telefono: String,
                     ) {
                        createProveedor(
                        numero: $numero,
                        razon_social: $razon_social,
                        direccion: $direccion,
                        departamento: $departamento,
                        provincia: $provincia,
                        distrito: $distrito,
                        ubigeo: $ubigeo,
                        estado: $estado,
                        condicion: $condicion,
                        documento_id: $documento_id,
                        observacion: $observacion,
                        email: $email,
                        telefono: $telefono
                        ) {
                        ${recursos}
                      }
                    }`,
        variables: {
            numero, razon_social, direccion, departamento, provincia, distrito, ubigeo, estado, condicion,
            documento_id, observacion, email, telefono
        }
    }),
    getAll: (client, withEliminated, recursos) => client.query({
        query: gql`query proveedores($withEliminated: Boolean!){
            proveedores(withEliminated: $withEliminated) {
                  ${recursos}
              }
          }`,
        variables: {
            withEliminated
        },
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query proveedor($id: String!){
                proveedor(id: $id) {
                      ${recursos}
                  }
              }`,
        variables: {
            id
        },
        fetchPolicy: 'no-cache'
    }),
    getByParam: (client, param, recursos) => client.query({
        query: gql`query proveedoresByParam($param: String!){
                proveedoresByParam(param: $param) {
                      ${recursos}
                  }
              }`,
        variables: {
            param
        },
        fetchPolicy: 'no-cache'
    }),
    update: (client, {
        id, numero, razon_social, direccion, departamento, provincia, distrito, ubigeo, estado, condicion, documento_id,
        observacion, email, telefono
    }, recursos) => client.mutate({
        mutation: gql`mutation updateProveedor($id: String!, $update: JSON!){
                  updateProveedor(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id,
            update: {
                numero, razon_social, direccion, departamento, provincia, distrito, ubigeo, estado, condicion,
                documento_id, observacion, email, telefono
            }
        }
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deletedProveedor($id: String!){
                  deletedProveedor(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id
        }
    }),
    activar: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activarProveedor($id: String!){
                  activarProveedor(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id
        }
    }),
    getByParam2: (client, param, recursos) => client.query({
        query: gql`query proveedoresByParamMovimientos($param: String!){
                proveedoresByParamMovimientos(param: $param) {
                      ${recursos}
                  }
              }`,
        variables: {
            param
        },
        fetchPolicy: 'no-cache'
    }),
}

export default ProveedorModel