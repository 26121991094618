import gql from 'graphql-tag'

const TablaFacturacionModel = {
    getByNumTabla:(client, numero_tabla, recursos) => client.query({
        query: gql`query parametricosbytabla($numero_tabla: String!){
            parametricosbytabla(numero_tabla: $numero_tabla) {
                ${recursos}
            }
        }`,
        variables: {
            numero_tabla: numero_tabla
        },
        fetchPolicy: 'no-cache'
    }),
    tipoOperaciones:(client, recursos)=> client.query({
        query: gql `{
            tipoOperacion{
                ${recursos}            
            }
         }`
    }),
    getById:(client, id, recursos)=>client.query({
        query: gql`query parametricosById($id: String!){
            parametricosById(id: $id) {
                ${recursos}
            }
        }`,
        variables: {
            id
        },
        fetchPolicy: 'no-cache'
    })
}
export default TablaFacturacionModel