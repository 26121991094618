import gql from 'graphql-tag'

const SellerModel = {
    SellerInsert: (client, {id, enviroment}) => client.mutate({
        mutation: gql`mutation SellerInsert(
            $id_user    : String
            $enviroment : JSON
        ){
            SellerInsert(
                id_user    : $id_user
                enviroment : $enviroment
            ){
                id
            }
        }`,
        variables: {
            id_user: id,
            enviroment
        }
    })
}

export default SellerModel