import gql from 'graphql-tag'

const TipoDocumentoModel = {
    getAll: (client, recursos) => client.query({
        query: gql`query documentoslist {
          documentoslist {
            ${recursos}
          }
        }`,
        fetchPolicy: 'no-cache'
    }),
    getEmail: (client, email) => client.query({
        query: gql`query getGmail(
                $gmail: String,
            ) {
            getGmail(
                gmail: $gmail,
            ) {
                email
            }
        }`,
        variables: {
            gmail: email
        },
        fetchPolicy: 'no-cache'
    })
}
export default TipoDocumentoModel