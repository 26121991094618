import React, {Fragment, useEffect, useState} from "react"
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap"
import {faCheckCircle, faTrash} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {Redirect} from "react-router-dom"
import BlockUi from "react-block-ui"
import Loader from "react-loaders"
import moment from "moment"

import ReactTable from "../../Components/ReactTable"
import Tooltip from "../../Components/Tooltip"
import {Empresa, Model, Modulo, Permission} from "../../Components/Models"
import AppHeader from "../../Layout/AppHeader"
import AppFooter from "../../Layout/AppFooter"
import {decodeToken, getNavId} from "../../utils/scripts"
import {MyLabel} from "../../Components"
import {useAuth} from "../../Context"
import {useInput, useSelect} from "../../hooks"

require('dotenv').config()

const Modulos = ({history}) => {
    require('../../assets/app/cardModule.css')
    const {client, toast, auth, setAuth, setCompany, company} = useAuth(),
        {id: idNav} = getNavId(),
        [modulos, setModulos] = useState([]),
        [id, setId] = useState([]),
        [empresas, setEmpresas] = useState([]),
        [enable, setEnable] = useState(true),
        [show, setShow] = useState(false),
        [show1, setShow1] = useState(false),

        [locales, selectLocales, setLocales, , optionsLocales] = useSelect({
            initialState: 'ticket'
        }),
        [periodo, inputPeriodo, setPeriodo] = useInput({
            typeState: 'month'
        }),

        selectEmpresa = (empresa_id) => {
            setShow1(true)
            Empresa
                .getById(client, empresa_id, 'id, locales {id, direccion, activo, nombre_corto}')
                .then(response => {
                    const {empresa} = response.data

                    if (decodeToken(auth.authentication).role_id === '203e6a2a-220c-11ec-9fa0-2bae39dbcd1e' || decodeToken(auth.authentication).role_id === 'df8d2d90-3c2d-11ec-bfca-971a2d2dff9b') {
                        let newActivo = false, newId = ''
                        const locales = empresa.locales.filter(({id}) => id === decodeToken(auth.authorization).otherlocal)

                        optionsLocales(
                            locales.map(({id, direccion, activo, nombre_corto}) => {
                                newActivo = activo
                                newId = id
                                return {value: id, label: nombre_corto ? nombre_corto : direccion}

                            }))
                        setLocales(newActivo ? newId : '')
                    } else {
                        let newActivo = false, newId = ''
                        optionsLocales(empresa.locales.map(({id, direccion, activo, nombre_corto}) => {
                            newActivo = activo
                            newId = id
                            return {value: id, label: nombre_corto ? nombre_corto : direccion}
                        }))
                        setLocales(newActivo ? newId : '')
                    }
                })
                .catch(({message}) => {
                    toast.error(message, {autoClose: 5000})
                })
        },

        seleccionarEmpresa = () => {
            Empresa
                .update(client, {
                    id, update: {periodo, activa: true, local_id: locales}
                }, 'id, ruc, razon_social, periodo, rubro, local {id, direccion, nombre_corto}')
                .then(response => {
                    // const {updateEmpresa} = response.data
                    // setCompany(updateEmpresa)
                    Permission
                        .getPermission(client, {module: `${id}/24414f8c-1a66-11ec-9149-dfe7ef2f825e`})
                        .then(response => {
                            let {permits} = response.data.getPermission
                            setAuth(prevState => ({
                                ...prevState,
                                authorization: permits,
                                modulo_id: "24414f8c-1a66-11ec-9149-dfe7ef2f825e"
                            }))
                            toast.success(`Módulo Facturación seleccionado`, {autoClose: 5000})
                            // window.location.href = '/home'
                            history.push('/home')
                        })
                        .catch(({message}) => {
                            toast.error(message, {autoClose: 5000})
                        })
                    setShow(false)
                    setShow1(false)
                }).catch(({message}) => {
                toast.error(message, {autoClose: 5000})
            })
        },

        selectModulo = ({id, codigo, descripcion}) => {
            if ('24414f8c-1a66-11ec-9149-dfe7ef2f825e' !== id) {
                Permission
                    .getPermission(client, {module: id})
                    .then(response => {
                        let {permits} = response.data.getPermission
                        setAuth(prevState => ({...prevState, authorization: permits, modulo_id: id}))
                        toast.success(`Módulo ${descripcion} seleccionado`, {autoClose: 5000})
                        history.push('/home')
                        // window.location.href = '/home'
                    })
                    .catch(({message}) => {
                        toast.error(message, {autoClose: 5000})
                    })
            } else {
                setShow(true)
            }
        },

        columns = [
            {
                Header: '#', style: {textAlign: 'center'},
                Cell: ({row}) => {
                    const {original} = row
                    return (
                        <div className="text-center"
                             style={{color: company ? (company.id === original.id ? 'rgb(58, 196, 125)' : '') : ''}}>
                            {row.index + 1}
                        </div>
                    )
                }
            },
            {
                Header: 'RUC', accessor: 'ruc', style: {textAlign: 'left'},
                Cell: ({row}) => {
                    const {original} = row
                    return (
                        <div className="text-left"
                             style={{color: company ? (company.id === original.id ? 'rgb(58, 196, 125)' : '') : ''}}>
                            {original.ruc}
                        </div>
                    )
                },
            },
            {
                Header: 'RAZÓN SOCIAL', accessor: 'razon_social', style: {textAlign: 'left'},
                Cell: ({row}) => {
                    const {original} = row
                    return (
                        <div className="text-left"
                             style={{color: company ? (company.id === original.id ? 'rgb(58, 196, 125)' : '') : ''}}>
                            {original.razon_social}
                        </div>
                    )
                },
            },
            {
                Header: ' ',
                accessor: 'seleccionar',
                Cell: ({row}) => {
                    const {original} = row
                    return (
                        <div className="text-center">
                            <Button id={`Tooltip-${original.id}-z`} className='btn-icon btn-icon-only p-0'
                                    color='link' onClick={() => {
                                selectEmpresa(original.id)
                                setLocales('')
                                setPeriodo(moment().format('YYYY-MM'))
                                setId(original.id)
                            }}
                                    disabled={original.deleted_at}>Seleccionar</Button>
                            <Tooltip id={`Tooltip-${original.id}-z`} name='Seleccionar Empresa'/>
                        </div>
                    )
                },
                style: {
                    textAlign: 'center'
                }
            },
        ]

    useEffect(() => {
        const {role_id, module, otherempresa} = auth.authorization ? decodeToken(auth.authorization) : {
            role_id: '', module: []
        }
        if (['fd2b32f2-220b-11ec-a62c-57087e024016'].includes(role_id)) {
            history.push('/home')
            return
        }
        if (['1990d988-220c-11ec-9b6d-335378e45614', '129965aa-220c-11ec-adf6-5784bddb7a64', '0f17e438-220c-11ec-adf5-77be0344e22b', 'fc7a149a-e5e7-11ec-88f5-18c04dc1e3d5'].includes(role_id)) {
            history.push('/mcc/pedido/create')
            return
        }

        if (role_id === '203e6a2a-220c-11ec-9fa0-2bae39dbcd1e') {
            Permission
                .getPermission(client, {module: `${otherempresa[0]}/24414f8c-1a66-11ec-9149-dfe7ef2f825e`})
                .then(response => {
                    let {permits} = response.data.getPermission
                    setAuth(prevState => ({
                        ...prevState,
                        modulo_id: "24414f8c-1a66-11ec-9149-dfe7ef2f825e"
                    }))
                    toast.success(`Módulo Facturación seleccionado`, {autoClose: 5000})
                    history.push('/home')
                })
                .catch(({message}) => {
                    toast.error(message, {autoClose: 5000})
                })
        }

        Modulo.getAll(client, 'id, codigo, descripcion, detalle').then(response => {
            let {modulos} = response.data
            if (role_id === '04a0a9c2-220c-11ec-bb77-6369172f77f0' || role_id === '470e28ec-22fd-4985-b918-9a711745982f') {
                modulos = modulos.filter(i => module.find(j => j === i.id))
            }
            if (role_id === 'fd2b32f2-220b-11ec-a62c-57087e024016') {
                modulos = modulos.filter(i => i.id === '24414f8c-1a66-11ec-9149-dfe7ef2f825e')
            }
            if (role_id !== 'ea7e35be-220b-11ec-bdf8-13a4a75f3041' && role_id !== 'f4539214-220b-11ec-ae30-f3f6e9fe190d' && role_id !== '04a0a9c2-220c-11ec-bb77-6369172f77f0' && role_id !== '470e28ec-22fd-4985-b918-9a711745982f') {
                modulos = modulos.filter(i => i.id !== '33bb278a-1a66-11ec-9e9f-8b03442b4675')
            }
            setModulos(modulos)
        })
            .catch(({message}) => toast.error(message, {autoClose: 5000}))
    }, [])

    useEffect(() => {
        if (show) {
            let query = 'empresas: empresas{id, ruc, razon_social, direccion, deleted_at, periodo, activa, regimen_planilla} '
            Model
                .getAll(client, query, idNav)
                .then(response => {
                    let {empresas, getSoporte} = response.data,
                        arrays = empresas.concat(getSoporte ? getSoporte : [])

                    if (decodeToken(auth.authentication).role_id === '04a0a9c2-220c-11ec-bb77-6369172f77f0' || decodeToken(auth.authentication).role_id === '470e28ec-22fd-4985-b918-9a711745982f') {
                        let array = []
                        if ((decodeToken(auth.authorization).otherempresa)) {
                            empresas.map(item => {
                                (decodeToken(auth.authorization).otherempresa).map(tem => {
                                    if (item.id === tem) array.push(item)
                                })
                            })
                            setEmpresas(array)
                        } else
                            setEmpresas(array)
                    }

                    if (decodeToken(auth.authentication).role_id === 'fd2b32f2-220b-11ec-a62c-57087e024016' && decodeToken(auth.authorization).otherempresa !== null)
                        setEmpresas(empresas.filter(item => item.id === decodeToken(auth.authorization).otherempresa[0]))

                    if (decodeToken(auth.authentication).role_id === '203e6a2a-220c-11ec-9fa0-2bae39dbcd1e' || decodeToken(auth.authentication).role_id === 'df8d2d90-3c2d-11ec-bfca-971a2d2dff9b' && decodeToken(auth.authorization).otherempresa !== null && decodeToken(auth.authorization).otherlocal !== null)
                        setEmpresas(empresas.filter(item => item.id === decodeToken(auth.authorization).otherempresa[0]))

                    if (decodeToken(auth.authentication).role_id === 'f4539214-220b-11ec-ae30-f3f6e9fe190d')
                        setEmpresas(empresas)

                    if (decodeToken(auth.authentication).role_id === 'ea7e35be-220b-11ec-bdf8-13a4a75f3041')
                        setEmpresas(arrays)

                    setEnable(false)
                })
                .catch(({message}) => {
                    toast.error(message, {autoClose: 2000})
                })
        }
    }, [show])

    return auth.authentication ? (
        <Fragment>
            <AppHeader/>
            <div className='app-main'>
                <div className='app-main__outer p-0'>
                    <div className='app-main__inner'>
                        <Card className='main-card'>
                            <CardHeader>
                                <i className="header-icon lnr-graduation-hat icon-gradient bg-happy-itmeo"/>LISTA DE
                                MÓDULOS
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    {
                                        modulos.map(({id, codigo, descripcion, detalle}, index) =>
                                            <Col
                                                md={Math.trunc(12 / modulos.length) + (Number(12 % modulos.length) > 0 ? 1 : 0)}
                                                key={index}>
                                                <Card className="mb-2 smartb-card" body inverse color="primary"
                                                      onClick={() => selectModulo({id, codigo, descripcion})}>
                                                    <CardTitle
                                                        className="text-white">{codigo} - {descripcion}</CardTitle>
                                                    {detalle}
                                                </Card>
                                            </Col>)
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                    <AppFooter/>
                </div>
            </div>
            <Modal isOpen={show} toggle={() => show} keyboard={true} size={"lg"} backdrop={false}>
                <ModalHeader toggle={() => show} style={{border: '0px solid'}}
                             close={<button className="close" onClick={() => setShow(false)}> &times; </button>}>
                    Seleccionar Empresa
                </ModalHeader>
                <h3 style={{textAlign: 'center'}}><strong>Lista de Empresas</strong></h3>
                <BlockUi tag='div' blocking={enable} loader={<Loader active type='ball-pulse'/>}>
                    <ModalBody>
                        <ReactTable {...{
                            columns: columns,
                            data: empresas
                        }} />
                    </ModalBody>
                </BlockUi>
            </Modal>
            <Modal isOpen={show1} toggle={() => show1} keyboard={true} size={"xm"} backdrop={false}>
                <ModalHeader toggle={() => show1} style={{border: '0px solid'}}
                             close={<button className="close" onClick={() => setShow1(false)}> &times; </button>}>
                    Seleccionar Empresa
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <MyLabel name='Locales' requerid/>
                            {selectLocales}
                        </Col>
                        <Col md={12}>
                            <MyLabel name='Periodo' requerid/>
                            {inputPeriodo}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => setShow1(false)}>
                        <FontAwesomeIcon icon={faTrash}/> Cancelar
                    </Button>
                    <Button color="primary" disabled={!(String(locales).length > 4 && String(periodo).length > 3)}
                            onClick={() => seleccionarEmpresa()}>
                        <FontAwesomeIcon icon={faCheckCircle}/> Seleccionar
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    ) : (<Redirect to={'/login'}/>)
}

export default Modulos