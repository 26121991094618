import React, {Fragment, useEffect, useState} from "react"
import Chart from "react-apexcharts"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChartLine} from "@fortawesome/free-solid-svg-icons"

import {useAuth} from "../../../Context"
import {Home} from "../../../Components/Models"

const ComprasVentas = () => {
    const {client} = useAuth()

    const [mixedChart, setMixedChart] = useState({
        options: {
            chart: {height: 350, type: 'area', toolbar: {show: true}},
            stroke: {width: [4, 4]},
            labels: [],
            xaxis: {type: 'month'},
            yaxis: [{title: {text: 'Importes'}}]
        },
        series: []
    })

    useEffect(() => {
        Home
            .comprasVentasByMonths(client, 'success, labels, series')
            .then(response => {
                const {success, labels, series} = response.data.comprasVentasByMonths
                setMixedChart(prevState => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        labels
                    },
                    series
                }))
            })
    }, [])

    return (
      <Fragment>
          <span style={{fontWeight: 'bold'}}>
              <FontAwesomeIcon icon={faChartLine} className='text-warning mr-2'/>COMPRAS Y VENTAS
          </span>
          <div className="bar">
              <Chart options={mixedChart.options} series={mixedChart.series} type="line"
                     width="100%" height="300px"/>
          </div>
      </Fragment>
    )
}

export default ComprasVentas