import gql from 'graphql-tag'

const OperacionModel = {
    create: (client, {
        cuo, periodo, operacion_id, subdiario_id, glosa, glosa_referencia, comprobante_id, serie, correlativo,
        fecha_emision, fecha_vencimiento, fecha_contable, moneda_id, tipo_cambio, dato_estructurado,
        tipo_operacion_kardex, tipo_cdp_regimen_sunat, serie_cdp_regimen_sunat, correlativo_cdp_regimen_sunat,
        importe_cdp_regimen_sunat, persona_asociado_id, movimiento, venta, codigo_libro
    }, recursos) => client.mutate({
        mutation: gql`mutation createOperacion(
                        $cuo: String,
                        $periodo: String,
                        $operacion_id: String,
                        $subdiario_id: String,
                        $glosa: String,
                        $glosa_referencia: String,
                        $comprobante_id: String,
                        $serie: String, 
                        $correlativo: String, 
                        $fecha_emision: String,
                        $fecha_vencimiento: String,
                        $fecha_contable: String,
                        $moneda_id: String,
                        $tipo_cambio: Float, 
                        $dato_estructurado: String, 
                        $tipo_operacion_kardex: String, 
                        $tipo_cdp_regimen_sunat: String,
                        $serie_cdp_regimen_sunat: String, 
                        $correlativo_cdp_regimen_sunat: String, 
                        $importe_cdp_regimen_sunat: Float,
                        $persona_asociado_id: String,
                        $movimiento: JSON,
                        $venta: JSON,
                        $codigo_libro: String
                     ) {
                        createOperacion(
                        cuo: $cuo,
                        periodo: $periodo,
                        operacion_id: $operacion_id,
                        subdiario_id: $subdiario_id,
                        glosa: $glosa,
                        glosa_referencia: $glosa_referencia,
                        comprobante_id: $comprobante_id,
                        serie: $serie,
                        correlativo: $correlativo,
                        fecha_emision: $fecha_emision,
                        fecha_vencimiento: $fecha_vencimiento,
                        fecha_contable: $fecha_contable,
                        moneda_id: $moneda_id,
                        tipo_cambio: $tipo_cambio,
                        dato_estructurado: $dato_estructurado,
                        tipo_operacion_kardex: $tipo_operacion_kardex,
                        tipo_cdp_regimen_sunat: $tipo_cdp_regimen_sunat,
                        serie_cdp_regimen_sunat: $serie_cdp_regimen_sunat,
                        correlativo_cdp_regimen_sunat: $correlativo_cdp_regimen_sunat,
                        importe_cdp_regimen_sunat: $importe_cdp_regimen_sunat,
                        persona_asociado_id: $persona_asociado_id,
                        movimiento: $movimiento,
                        venta: $venta,
                        codigo_libro: $codigo_libro
                        ) {
                        ${recursos}
                      }
                    }`,
        variables: {
            cuo, periodo, operacion_id, subdiario_id, glosa, glosa_referencia, comprobante_id, serie, correlativo,
            fecha_emision, fecha_vencimiento, fecha_contable, moneda_id, tipo_cambio, dato_estructurado,
            tipo_operacion_kardex, tipo_cdp_regimen_sunat, serie_cdp_regimen_sunat, correlativo_cdp_regimen_sunat,
            importe_cdp_regimen_sunat, persona_asociado_id, movimiento, venta, codigo_libro
        }
    }),
    getLastCuo: (client, recursos) => client.query({
        query: gql`{
              lastCuo {
                ${recursos}
              }
            }`,
        fetchPolicy: "no-cache"
    }),
    getAllByLibro814: (client, {libro, limite, fecha_desde, fecha_hasta, isBody}) => client.query({
        query: gql`query  operacionesByLibro814($libro: String!, $limite: JSON, $fecha_desde: String, $fecha_hasta: String, $isBody: Boolean) {
                 operacionesByLibro814(libro: $libro, limite: $limite, fecha_desde: $fecha_desde, fecha_hasta: $fecha_hasta, isBody: $isBody) {
                      result
                  }
              }`,
        variables: {libro, limite, fecha_desde, fecha_hasta, isBody},
        fetchPolicy: 'no-cache'
    }),
    getAllByLibro: (client, {libro, limite}, recursos) => client.query({
        query: gql`query operacionesByLibro($libro: String!, $limite: JSON) {
                operacionesByLibro(libro: $libro, limite: $limite) {
                      ${recursos}
                  }
              }`,
        variables: {libro, limite},
        fetchPolicy: 'no-cache'
    }),
    contarLibro: (client, {libro, fecha_desde, fecha_hasta, isBody}, recursos) => client.query({
        query: gql`query countLibro($libro: String!, $fecha_desde: String, $fecha_hasta: String, $isBody: Boolean) {
                    countLibro(libro: $libro, fecha_desde: $fecha_desde, fecha_hasta: $fecha_hasta, isBody: $isBody){
                    ${recursos}        
              }
            }`,
        variables: {libro, fecha_desde, fecha_hasta, isBody},
        fetchPolicy: 'no-cache'
    }),
    libroByParam814: (client, {libro, param, fecha_desde, fecha_hasta, isBody}, recursos) => client.query({
        query: gql`query libroBYParam814($libro: String!, $param: String!,$fecha_desde: String, $fecha_hasta: String, $isBody: Boolean) {
                 libroBYParam814(libro: $libro, param: $param, fecha_desde: $fecha_desde, fecha_hasta: $fecha_hasta, isBody: $isBody){
                 result        
             }
      }`,
        variables: {libro, param, fecha_desde, fecha_hasta, isBody},
        fetchPolicy: 'no-cache'
    }),
    libroByParam: (client, {libro, param}, recursos) => client.query({
        query: gql`query libroBYParam($libro: String!, $param: String!){
                 libroBYParam(libro: $libro, param: $param){
                 ${recursos}        
             }
      }`,
        variables: {libro, param},
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query getOperacion($id: String!){
                operacion(id: $id) {
                    ${recursos}
                }
            }`,
        variables: {id},
        fetchPolicy: "no-cache"
    }),
    getParam: (client, {criterio, param, libro, limite, estado}, recursos) => client.query({
        query: gql`query operacionesByParam($criterio: String!, $param: String!, $libro: String!, $limite: JSON, $estado: String!){
                operacionesByParam(criterio: $criterio, param: $param, libro: $libro, limite: $limite, estado: $estado){
                    ${recursos}
                }
            }`,
        variables: {criterio, param, libro, limite, estado},
        fetchPolicy: "no-cache"
    }),
    getCorrelativo: (client, {comprobante_id, serie}, recursos) => client.query({
        query: gql`query correlativoOperacion($serie: String, $comprobante_id: String){
            correlativoOperacion(serie: $serie, comprobante_id: $comprobante_id){
                ${recursos}
            }         
        }`,
        variables: {comprobante_id, serie},
        fetchPolicy: "no-cache"
    }),
    getExternalId: (client, {comprobante_id, correlativo, serie}, recursos) => client.query({
        query: gql`query getExternbalId($correlativo: String, 
                   $comprobante_id: String, $serie: String){
                   getExternbalId(correlativo:$correlativo, comprobante_id:$comprobante_id, 
                   serie: $serie){
                    ${recursos}
                   }         
            }`,
        variables: {comprobante_id, correlativo, serie},
        fetchPolicy: "no-cache"
    }),
    getAllFacturas: (client, recursos) => client.query({
        query: gql`{
        facturas {
            ${recursos}
        }}`,
        context: {timeout: 480000},
        fetchPolicy: 'no-cache'
    }),
    getFacturasLimit: (client, limite, recursos) => client.query({
        query: gql`query facturas($limite: JSON) {
            facturas(limite: $limite) {
                result
            }
        }`,
        variables: {limite},
        fetchPolicy: 'no-cache'
    }),
    validarSaldos: (client, saldos, recursos) => client.query({
        query: gql`query validarSaldos($saldos: JSON!){
            validarSaldos(saldos: $saldos, isBody: true){
            ${recursos}
            }
        }`,
        variables: {saldos},
        context: {timeout: 720000},
        fetchPolicy: 'no-cache'
    }),
    createSaldosMasivos: (client, saldos, recursos) => client.mutate({
        mutation: gql`mutation createSaldoMasivo($saldos: JSON!){
                  createSaldoMasivo(saldos: $saldos) {
                    ${recursos}
                  }
                }`,
        context: {timeout: 720000},
        variables: {saldos}
    }),
    getPreViewById: (client, {id, sizee, guiasRemision, contenedor}, recursos) => client.query({
        query: gql`query preViewById($id:String!, $sizee: String, $guiasRemision: Boolean, $contenedor: Boolean) {
                preViewById(id: $id, sizee: $sizee, guiasRemision: $guiasRemision, contenedor: $contenedor) {
                   ${recursos}
                }
        }`,
        variables: {id, sizee, guiasRemision, contenedor},
        fetchPolicy: 'no-cache'
    }),
    getAllContingencias: (client, recursos) => client.query({
        query: gql`{
        contingencias {
            ${recursos}
        }}`,
        fetchPolicy: 'no-cache'
    }),
    getContingenciasLimit: (client, limite, recursos) => client.query({
        query: gql`query contingencias($limite: JSON){
                    contingencias(limite: $limite){
                        result
                    }
               }`,
        variables: {limite},
        fetchPolicy: 'no-cache',
    }),
    getOperacionExiste: (client, {comprobante_id, persona_asociado_id, serie, correlativo}, recursos) => client.query({
        query: gql`query operacionExiste($comprobante_id: String!, $persona_asociado_id: String!, $serie: String!, $correlativo: String!) {
                operacionExiste(comprobante_id: $comprobante_id, persona_asociado_id: $persona_asociado_id, serie: $serie, correlativo: $correlativo) {
                   ${recursos}
                }
        }`,
        variables: {comprobante_id, persona_asociado_id, serie, correlativo},
        fetchPolicy: 'no-cache'
    }),
    excelCPE: (client, {contingencias, nota, buscar, tipo}, recursos) => client.query({
        query: gql`query excelCPE($contingencias: Boolean, $nota: Boolean, $buscar: JSON, $tipo: String,){
        excelCPE(contingencias: $contingencias, nota: $nota, buscar: $buscar, tipo: $tipo){
            ${recursos}
        }}`,
        context: {timeout: 960000},
        variables: {contingencias, nota, buscar, tipo},
        fetchPolicy: 'no-cache'
    }),
    getXmlCpe: (client, {empresa, comprobante, serie, correlativo}, recursos) => client.query({
        query: gql`query xmlCpe($empresa: String, $comprobante: String!, $serie: String!, $correlativo: String!){
                xmlCpe(empresa: $empresa, comprobante: $comprobante, serie: $serie, correlativo: $correlativo){
                    ${recursos}
                }
            }`,
        variables: {empresa, comprobante, serie, correlativo},
        fetchPolicy: "no-cache"
    }),
    preViewPdfSinValidez: (client, {dataPdf}, recursos) => client.query({
        query: gql`query preViewPdfSinValidez($dataPdf : JSON) {
                preViewPdfSinValidez(dataPdf: $dataPdf) {
                   ${recursos}
                }
        }`,
        variables: {dataPdf},
        fetchPolicy: 'no-cache'
    }),
    getCpePendientes: (client, recursos) => client.query({
        query: gql`{
              cpePendientes {
                ${recursos}
              }
            }`,
        fetchPolicy: "no-cache"
    }),
    getAllSearch: (client, {fecha_desde, fecha_hasta, limite}, recursos) => client.query({
        query: gql`query getVentasSearch($fecha_desde: String!, $fecha_hasta: String!, $limite: JSON) {
            ventasSearch(fecha_desde: $fecha_desde, fecha_hasta: $fecha_hasta, limite: $limite) {
                   result
                }
        }`,
        variables: {fecha_desde, fecha_hasta, limite},
        context: {timeout: 480000},
        fetchPolicy: 'no-cache'
    }),
    getAllContingenciaSearch: (client, {fecha_desde, fecha_hasta, limite}, recursos) => client.query({
        query: gql`query ContingenciasSearch($fecha_desde: String!, $fecha_hasta: String!, $limite: JSON) {
            ContingenciasSearch(fecha_desde: $fecha_desde, fecha_hasta: $fecha_hasta, limite:$limite) {
                  result
                }
        }`,
        variables: {fecha_desde, fecha_hasta, limite},
        context: {timeout: 480000},
        fetchPolicy: 'no-cache'
    }),
    delete: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteOperacion($id: String!) {
                        deleteOperacion(id: $id) {
                        ${recursos}
                      }
                    }`,
        variables: {id}
    }),
    getAllNotas: (client, recursos) => client.query({
        query: gql`{
        notas {
            ${recursos}
        }}`,
        context: {timeout: 480000},
        fetchPolicy: 'no-cache'
    }),
    getNotasLimit: (client, limite, recursos) => client.query({
        query: gql`
        query notas($limite: JSON){
              notas(limite: $limite){
               result
              }  
        }
        `,
        variables: {limite},
        fetchPolicy: 'no-cache'
    }),
    contarAll: (client, isContingencia, recursos) => client.query({
        query: gql`query contarOperaciones($isContingencia: Boolean){
            contarOperaciones(isContingencia: $isContingencia){
                ${recursos}
            }
        }`,
        variables: {isContingencia},
        fetchPolicy: 'no-cache'
    }),
    contarNotas: (client, recursos) => client.query({
        query: gql`{
        contarNotas {
            ${recursos}
        }}`,
        context: {timeout: 480000},
        fetchPolicy: 'no-cache'
    }),
    notasByParam: (client, param, recursos) => client.query({
        query: gql`query notasByparam($param: String){
                notasByparam(param: $param){
                    result
                }
        }`,
        variables: {param},
        fetchPolicy: 'no-cache'
    }),
    contarBusqueda: (client, {fecha_desde, fecha_hasta, isContingencia}, recursos) => client.query({
        query: gql`query contarBusqueda($fecha_desde: String!, $fecha_hasta: String!, $isContingencia: Boolean){
                contarBusqueda(fecha_desde: $fecha_desde, fecha_hasta: $fecha_hasta, isContingencia: $isContingencia){
                    ${recursos}
                }
        }`,
        variables: {fecha_desde, fecha_hasta, isContingencia},
        fetchPolicy: 'no-cache'
    }),
    busquedaByParam: (client, {fechas, isContingencia, param}, recursos) => client.query({
        query: gql`query searchByparam($fechas: JSON, $isContingencia: Boolean, $param: String){
        searchByparam(fechas: $fechas, isContingencia: $isContingencia, param: $param){
            result
            }}`,
        variables: {fechas, isContingencia, param},
        fetchPolicy: 'no-cache'
    }),
    countParam: (client, {criterio, param, libro, estado}, recursos) => client.query({
        query: gql`query operacionesCriterioCount($criterio: String!, $param: String!, $libro: String!, $estado: String!){
                operacionesCriterioCount(criterio: $criterio, param: $param, libro: $libro, estado: $estado){
                    ${recursos}
                }
            }`,
        variables: {criterio, param, libro, estado},
        fetchPolicy: "no-cache"
    }),
    busquedaCriterioByParam: (client, {criterio, param, libro, parametro, estado}, recursos) => client.query({
        query: gql`query busquedaCriterioByParam($criterio: String!, $param: String!, $libro: String!, $parametro: String!, $parametro: String!, $estado: String!){
                busquedaCriterioByParam(criterio: $criterio, param: $param, libro: $libro, parametro: $parametro, estado: $estado){
                    ${recursos}
                }
            }`,
        variables: {criterio, param, libro, parametro, estado},
        fetchPolicy: "no-cache"
    }),
    getAllGuias: (client, {limite, fecha_desde, fecha_hasta}, recursos) => client.query({
        query: gql`query guias($limite: JSON, $fecha_desde: String, $fecha_hasta: String) {
            guias(limite: $limite, fecha_desde: $fecha_desde, fecha_hasta: $fecha_hasta) {
                ${recursos}
            }
        }`,
        variables: {limite, fecha_desde, fecha_hasta},
        context: {timeout: 480000},
        fetchPolicy: 'no-cache'
    }),
    validarFacturacionMasiva: (client, datos) => client.query({
        query: gql`
        query validarMasivoFacturacion($datos: JSON){
                validarMasivoFacturacion(datos: $datos){
                result
             }
        }`,
        context: {timeout: 480000},
        variables: {datos},
        fetchPolicy: 'no-cache'
    }),
    createFacturacionMasiva: (client, datos, mergePDF, recursos) => client.query({
        query: gql`query createOperacionesMasivos($datos: JSON, $mergePDF: Boolean){
            createOperacionesMasivos(datos: $datos, mergePDF: $mergePDF){
                ${recursos}
            }
        }`,
        context: {timeout: 20 * 60 * 1000},
        variables: {datos, mergePDF}
    }),
    getReporteMovimientos: (client, {
                                tipo, fecha_inicial, fecha_final, cliente, categoria, producto, excel, pdf,
                                type, vendedor, id_trabajador
                            },
                            recursos) => client.query({
        query: gql`query reporteMovimientos($tipo: String, $fecha_inicial: String, $fecha_final: String, $cliente: String, $categoria: String, $producto: String, $excel: Boolean, $type: String, $vendedor: String, $pdf: Boolean, $id_trabajador: String){
        reporteMovimientos(tipo: $tipo, fecha_inicial: $fecha_inicial, fecha_final: $fecha_final, cliente: $cliente, categoria: $categoria, producto: $producto, excel: $excel, type: $type, vendedor: $vendedor, pdf: $pdf, id_trabajador: $id_trabajador){
            ${recursos}
        }}`,
        variables: {
            tipo, fecha_inicial, fecha_final, cliente, categoria, producto, excel, type, vendedor, pdf, id_trabajador
        },
        fetchPolicy: 'no-cache'
    }),
    excelUtilitarioMasivoFacturas: (client, {views}, recursos) => client.query({
        query: gql`query excelUtilitarioMasivoFacturas($views: JSON){
        excelUtilitarioMasivoFacturas(views: $views){
            ${recursos}
        }}`,
        variables: {views},
        fetchPolicy: 'no-cache'
    }),
    getOperacionDiarioByCuenta: (client, cuenta, recursos) => client.query({
        query: gql`query getOperacionDiarioByCuenta($cuenta: String!){
                operacionDiarioByCuenta(cuenta: $cuenta) {
                    ${recursos}
                }
            }`,
        variables: {cuenta},
        fetchPolicy: "no-cache"
    }),
    getByParam: (client, param, recursos) => client.query({
        query: gql`query comprobanteByParam($param: String){
                comprobanteByParam(param: $param){
                    ${recursos}
                }
        }
        `,
        variables: {param},
        fetchPolicy: "no-cache"
    }),
    get10: (client, recursos) => client.query({
        query: gql`{
        buscador10Comprobantes {
            ${recursos}
        }}`,
        context: {timeout: 480000},
        fetchPolicy: 'no-cache'
    }),
    guiabySerieCorrelativo: (client, param) => client.query({
        query: gql`query guiaBySerieCorrelativo($param: String){
                guiaBySerieCorrelativo(param: $param){
                   success
                }
        }
        `,
        variables: {param},
        fetchPolicy: "no-cache"
    }),
    updateLe141: (client, {id, update, periodo}, recursos) => client.mutate({
        mutation: gql`mutation updateLe141($id: String!, $update: JSON!, $periodo: String!){
                  updateLe141(id: $id, update: $update, periodo: $periodo) {
                    ${recursos}
                  }
                }`,
        variables: {id, update, periodo},
    }),
    updateLe81: (client, {id, update, periodo}, recursos) => client.mutate({
        mutation: gql`mutation updateLe81($id: String!, $update: JSON!, $periodo: String!){
                  updateLe81(id: $id, update: $update, periodo: $periodo) {
                    ${recursos}
                  }
                }`,
        variables: {id, update, periodo},
    }),
    updateLe82: (client, {id, update, periodo}, recursos) => client.mutate({
        mutation: gql`mutation updateLe82($id: String!, $update: JSON!, $periodo: String!){
                  updateLe82(id: $id, update: $update, periodo: $periodo) {
                    ${recursos}
                  }
                }`,
        variables: {id, update, periodo},
    }),
    updateDocumentsStateApifact: (client, {id, external_id, resumen}) => client.query({
        query: gql`query updateDocumentsStateApifact($id: String, $external_id: String, $resumen: Boolean){
                updateDocumentsStateApifact(id: $id, external_id: $external_id, resumen: $resumen){
                   status  
              }
        }`,
        variables: {id, external_id, resumen},
        fetchPolicy: 'no-cache'
    }),
    updateOperacion: (client, {id, update}, recursos) => client.query({
        query: gql`query updateGeneralOperacion($id: String, $update: JSON){
                updateGeneralOperacion(id: $id, update: $update){
                    ${recursos}  
              }
        }`,
        variables: {id, update},
        fetchPolicy: 'no-cache'
    }),
    saldosInicialesPeriodo: (client, recursos) => client.query({
        query: gql`{
        saldosIniciales {
            ${recursos}
        }}`,
        fetchPolicy: 'no-cache'
    }),
    movimientosBYOperacion: (client, {operacion_id, limite}) => client.query({
        query: gql`query movimientosByOperacion($operacion_id: String, $limite: JSON){
                movimientosByOperacion(operacion_id: $operacion_id, limite: $limite){
                    result
                }
        }`,
        variables: {operacion_id, limite},
        fetchPolicy: "no-cache"
    }),
    countMovimientosByOperacion: (client, operacion_id) => client.query({
        query: gql`query countMovimientosOperacion($operacion_id: String){
                countMovimientosOperacion(operacion_id: $operacion_id){
                    result                
                }
        }`,
        variables: {operacion_id},
        fetchPolicy: "no-cache"
    }),
    movimientosParamOperacion: (client, {operacion_id, param}) => client.query({
        query: gql`query movimientosOperacionParam($operacion_id: String, $param: String){
               movimientosOperacionParam(operacion_id: $operacion_id, param: $param){
                result    
           }
        }`,
        variables: {operacion_id, param},
        fetchPolicy: "no-cache"
    }),
    updateSaldosMasivos: (client, {data, operacion_id}) => client.query({
        query: gql`query updateSaldosMasivos($operacion_id: String, $data: JSON){
                    updateSaldosMasivos(operacion_id: $operacion_id, data: $data){
                    id      
              }
        }`,
        variables: {data, operacion_id},
        fetchPolicy: "no-cache",
        context: {timeout: 480000}
    }),
    guiasByParam: (client, {fechas, param}, recursos) => client.query({
        query: gql`query guiasByParam($fechas: JSON, $param: String){
            guiasByParam(fechas: $fechas, param: $param){
                ${recursos}
            }
        }`,
        variables: {fechas, param},
        fetchPolicy: 'no-cache'
    }),
    contarGuias: (client, {fecha_desde, fecha_hasta}, recursos) => client.query({
        query: gql`query contarGuias($fecha_desde: String, $fecha_hasta: String) {
            contarGuias(fecha_desde: $fecha_desde, fecha_hasta: $fecha_hasta) {
                ${recursos}
            }
        }`,
        variables: {fecha_desde, fecha_hasta},
        fetchPolicy: 'no-cache'
    })
}

export default OperacionModel
