import React from 'react'
import {Route, Redirect} from 'react-router-dom'

import {useAuth} from './Context'
import {decodeToken} from "./utils/scripts"

const PrivateRoute = ({component: Component, ...rest}) => {
    const {auth} = useAuth()
    const {authentication, modulo_id} = auth

    if (window.location.pathname.includes('reset') || window.location.pathname.includes('verify'))
        return <Route {...rest} render={props => <Component {...props} />}/>
    if (!authentication) return <Redirect to="/login"/>

    const {role_id} = authentication ? decodeToken(authentication) : {}
    if (!modulo_id && !['1990d988-220c-11ec-9b6d-335378e45614', '129965aa-220c-11ec-adf6-5784bddb7a64', '0f17e438-220c-11ec-adf5-77be0344e22b'].includes(role_id))
        return <Redirect to="/modulos"/>

    return <Route {...rest} render={props => <Component {...props} />}/>
}

export default PrivateRoute